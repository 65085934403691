import {runInAction} from "mobx";
import {updateComunicazioni} from "System/actions/comunicazioni";

const getInfoUtente = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'profilo/getuserdetails',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response) {

        if (response.data.details.fg_forza_logout) {
            await this.userLogout();
            return;
        }

        runInAction(() => {
            this.dataUserDetails = response.data.details;
        });

        if (this.config && this.config.comunicazioni && this.config.comunicazioni === true) {
            this.comunicazioniOnSync = true;

            this.dataLayer({
                url: this.config.paths.apiURL + 'comunicazioni/sync',
                cacheAge: this.config.cacheAPI.initData,
                params: {},
                userToken: this.userToken
            }).then(r => {
                    if (r.data.count > 0) {
                        this.log('Need to reload newsletter data','WARNING');
                        this.getLastMessages();
                        this.updateComunicazioni();
                    }
                    this.comunicazioniOnSync = false;
                    this.log('Newsletter sync completed','STORE');
                }
            );
        }

        if (this.config && this.config.popupAggiornamento && this.config.popupAggiornamento === true) {
            const softwareUpdate = await this.getLastVersionUpdate();

            if (softwareUpdate && response.data.details && response.data.details.hasOwnProperty('last_version_update') && response.data.details.last_version_update !== softwareUpdate.versione) {
                if (softwareUpdate.contenuto) {
                    this.modalOpen('ModalAggiornamentoSoftware', {update: softwareUpdate})
                }
            }
        }
    }

    return true;
}

const updateUserLastVersionRead = async function (params) {
    console.debug('Setting software update version for user');
    await this.dataLayer({
        url: this.config.paths.apiURL + 'profilo/updatelastversion',
        cacheAge: this.config.cacheAPI.initData,
        params: params,
        userToken: this.userToken
    });
}

const getBackEndConfigData = async function() {
    const backendConfig = await this.dataLayer({
        url: this.config.paths.apiURL + 'profilo/external-config',
        cacheAge: this.config.cacheAPI.initData,
        userToken: this.userToken
    });
    if (backendConfig) {
        runInAction(() => {
            this.externalConfig = backendConfig.data.result;
        });
    }
}

export {
    getInfoUtente,
    getBackEndConfigData,
    updateUserLastVersionRead,
}
