
import { toJS, runInAction } from "mobx";
import { loadClientiDisponibili } from '../../system/actions/acl.js';
import langstore from "../../system/i18n/translator.js";

const productsList = async function () {

  const loadingKey = "productsList";
  this.loadingAdd(loadingKey);

  const productsList = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/articoli/lista',
    cacheAge: null,
    userToken: this.userToken,
  });

  if (productsList) {
    runInAction(() => {
      this.dataEcomm.articoli = productsList.data;
      if (typeof this.dataEcomm.articoliOrderAndFilter === 'undefined') {
        this.dataEcomm.articoliOrderAndFilter = { searchBy: '', sorting: 'dt_ins', sortingDirection: 'desc' }
      }
      this.loading = false;
    });
  }

  this.loadingRemove(loadingKey);

}



const orderAndFilterProductsList = function (type, value) {

  const loadingKey = "orderAndFilterProductsList";
  this.loadingAdd(loadingKey);

  if (type) {
    runInAction(() => {
      this.dataEcomm.articoliOrderAndFilter[type] = value;
    });
  }

  this.loadingRemove(loadingKey);

}



const productDetails = async function () {

  const loadingKey = "productDetails";
  this.loadingAdd(loadingKey);

  const productId = (this.routeParamsCurrent.productId).split('-');
  const _params = {
    'c_articolo': productId[0],
    'c_scheda': productId[1]
  }

  const productDetails = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/articoli/dettaglio',
    cacheAge: null,
    params: _params,
    userToken: this.userToken,
  });

  if (productDetails) {
    runInAction(() => {
      this.dataEcomm.articoli = productDetails.data;
      this.loading = false;
    });
  }

  this.loadingRemove(loadingKey);

};



const checkIfCartExist = async function () {

  const cart = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/esiste',
    cacheAge: null,
    params: { c_utente: this.dataUserDetails.c_utente },
    userToken: this.userToken,
  });

  if (cart && cart.data) {
    runInAction(() => {
      this.dataEcomm.cartRiepilogue = cart.data;
      this.dataEcomm.cartTimeExpired = false;
    });
    return cart.data.c_guid;
  } else {

    if (this.dataEcomm?.cart?.[0]) {
      await this.automaticDeleteCart(this.dataEcomm.cart[0])
      runInAction(() => {
        this.dataEcomm.cartTimeExpired = true;
        delete this.dataEcomm.cart;
        delete this.dataEcomm.cartRiepilogue;
        delete this.dataEcomm.cartCreatedAt;
        delete this.dataEcomm.cartItemsCount;
        delete this.dataEcomm.cartItemsTotal;
        delete this.dataEcomm.cartValidUntil;
      })
      return false;
    }

  }

}



const getCartGuid = async function (c_utente) {

  // get cart GUID
  const cartTmp = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/guid',
    cacheAge: null,
    params: { c_utente: c_utente },
    userToken: this.userToken,
  });

  if (cartTmp && cartTmp.data) {
    return cartTmp.data.c_guid;
  }

};



const addToCart = async function (c_utente, c_articolo, c_scheda, qta, idx) {

  const c_guid = await this.getCartGuid(c_utente);

  const _params = {
    'c_guid': c_guid,
    'articolo': c_articolo,
    'scheda': c_scheda,
    'qta': qta
  }

  // add product to cart
  const cart = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/modifica-quantita',
    cacheAge: null,
    params: _params,
    userToken: this.userToken,
  });

  if (cart.data) {
    await this.cartDetails(cart.data.guid_ordine);
  }

  if (Math.round(cart.data.id_messaggio.slice(-2)) < 90) { // id_messaggio >= 90 means that the quantity has not been updated
    // update availability product data
    const saved_qta_disponibile = this.dataEcomm.articoli[idx].n_qta_disponibile;
    const saved_qta_impegno = this.dataEcomm.articoli[idx].n_qta_impegno;
    this.dataEcomm.articoli[idx].n_qta_disponibile = saved_qta_disponibile - qta;
    this.dataEcomm.articoli[idx].n_qta_impegno = saved_qta_impegno + qta;
  }
};



const cartDetails = async function (c_guid, isValid) {

  const is_valid = isValid === false ? false : true;

  // get cart details
  const cart = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/vedi',
    cacheAge: null,
    params: {
      c_guid: c_guid,
      is_valid: is_valid
    },
    userToken: this.userToken,
  });

  if (cart && cart.data && cart.data.length) {
    runInAction(() => {
      this.dataEcomm.cart = cart.data;
      this.dataEcomm.cartCreatedAt = new Date(cart.data[0].dt_ins).getTime();
      this.dataEcomm.cartValidUntil = new Date(cart.data[0].dt_validita).setMilliseconds(0); // need to round down to seconds (excluding milliseconds) to avoid wrong result on async func calls: example valid until '2023-11-02T10:50:29.890Z' and call done at '2023-11-02T10:50:29.300Z'
      this.dataEcomm.cartItemsCount = cart.data.map(art => !art.art_fg_riga_annullato && art.n_qta).reduce((sum, cur) => sum + cur, 0);
      this.dataEcomm.cartItemsTotal = cart.data.reduce((sum, art) => art.art_fg_riga_annullato ? sum : sum + (art.n_qta * art.prz_netto), 0);
      this.dataEcomm.cartTimeExpired = false;
    });
    return cart;
  }
  return false;
};



const initCart = async function () {

  const loadingKey = "initCart";
  this.loadingAdd(loadingKey);

  const c_guid = this.routeParamsCurrent.guid;
  const cart = await this.cartDetails(c_guid);

  if (cart && cart.data && cart.data.length) {

    if ('undefined' === typeof this.acl) {
      await this.loadClientiDisponibili();
    }

    let clienti = [];
    this.acl.clienti.map((c) => {
      clienti.push({
        'id': c.c_nome,
        'label': `${c.ragione_sociale} [${c.c_nome}]`
      });
    });

    const destinazioneId = await this.getDestinazioneByCNomeEcomm(clienti[0].id);

    const cartDataConfirmationPayload = {
      'n_anno': cart.data[0].n_anno,
      'c_numeratore': cart.data[0].c_numeratore,
      'n_ordine': cart.data[0].n_ordine,
      'c_cliente': clienti[0].id,
      'c_cliente_dest_merce': destinazioneId[0].n_progressivo,
      'rif_cliente': null,
    };

    runInAction(() => {
      this.dataEcomm.cartClientList = clienti;
      this.dataEcomm.cartDataConfirmationPayload = cartDataConfirmationPayload;
      this.loading = false;
    });
  }

  this.loadingRemove(loadingKey);

};



const initStoredCart = async function () {

  const loadingKey = "initCart";
  this.loadingAdd(loadingKey);

  const c_guid = this.routeParamsCurrent.guid;
  const cart = await this.cartDetails(c_guid, false);

  if (cart && cart.data && cart.data.length) {
    if (!this.dataEcomm.cartDestinations && cart.data[0].c_cliente) {
      await this.getDestinazioneByCNomeEcomm(cart.data[0].c_cliente);
    }
    runInAction(() => {
      this.dataEcomm.cartStored = cart.data;
      this.dataEcomm.cartStoredTotal = cart.data.reduce((sum, art) => sum + (art.n_qta * art.prz_netto), 0);
      this.loading = false;
    });
  }

  this.loadingRemove(loadingKey);

};



const getDestinazioneByCNomeEcomm = async function (c_cliente) {
  const destinations = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/destinazioni-merce',
    cacheAge: null,
    params: {
      c_cliente: c_cliente
    },
    userToken: this.userToken,
  });

  if (destinations && destinations.data && destinations.data.length) {
    runInAction(() => {
      this.dataEcomm.cartDestinations = destinations.data;
      if (this.dataEcomm.cartDataConfirmationPayload) {
        this.dataEcomm.cartDataConfirmationPayload.c_cliente_dest_merce = destinations.data[0].n_progressivo;
      }
    });
    return destinations.data;
  }
  return false;
};



const deleteRowFromCart = async function (art) {

  const loadingKey = "rowDeleteFromCart";
  this.loadingAdd(loadingKey);

  const _params = {
    'c_guid': art.c_guid,
    'articolo': art.c_articolo,
    'scheda': art.c_scheda,
    "qta": 0
  }

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/elimina-prodotto',
    cacheAge: null,
    params: _params,
    userToken: this.userToken,
  });

  if (result && result.data) {
    switch (result.data.id_messaggio) {
      case 'M03': // M03	riga annullata
        runInAction(() => {
          this.cartDetails(result.data.guid_ordine);
          this.loading = false;
        });
        break;
      case 'M04': // M04 ultima riga annullata
        runInAction(() => {
          this.cartDetails(result.data.guid_ordine);
          this.dataEcomm.cart = null;
          this.dataEcomm.cartItemsCount = null;
          this.dataEcomm.cartItemsTotal = null;
          this.loading = false;
        });
        break;
      default:
        this.notificationAdd('Error Code ' + result.data.id_messaggio + '. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.'), "ecommError", 10000, "error");
        break;
    }
  }

  this.loadingRemove(loadingKey);

};



const automaticDeleteCart = async function(cart) {
  return await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/annullamento-automatico',
    cacheAge: null,
    params: {
      'n_anno':       cart.n_anno,
      'c_numeratore': cart.c_numeratore,
      'n_ordine':     cart.n_ordine
    },
    userToken: this.userToken,
  });
};



const permanentlyDeleteCart = async function (c_guid) {

  const loadingKey = "permanentlyDeleteCart";
  this.loadingAdd(loadingKey);

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/elimina',
    cacheAge: null,
    params: {
      c_guid: c_guid
    },
    userToken: this.userToken,
  });

  if (result && result.data) {
    if (result.data.id_messaggio === 'M02') {
      runInAction(() => {
        this.dataEcomm.cart = null;
        this.dataEcomm.cartItemsCount = null;
        this.dataEcomm.cartItemsTotal = null;
        this.loading = false;
        this.modalClose();
      });
    } else {
      this.notificationAdd('Error Code ' + result.data.id_messaggio + '. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.'), "ecommError", 10000, "error");
    }
  }

  this.loadingRemove(loadingKey);

};



const confirmCart = async function () {

  const _params = this.dataEcomm.cartDataConfirmationPayload;

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/conferma',
    cacheAge: null,
    params: _params,
    userToken: this.userToken,
  });

  if (result && result.data && result.data[0].order_confirmed) {

    const url = '/ecommerce/storico-carrello/' + this.dataEcomm.cart[0].c_guid;
    this.routeChange(url);

    runInAction(() => {
      delete this.dataEcomm.cart;
      delete this.dataEcomm.cartCreatedAt;
      delete this.dataEcomm.cartDataConfirmationPayload;
      delete this.dataEcomm.cartItemsCount;
      delete this.dataEcomm.cartItemsTotal;
      delete this.dataEcomm.cartRiepilogue;
      delete this.dataEcomm.cartTimeExpired;
      delete this.dataEcomm.cartValidUntil;
    });
    return true;
  }
  return false;
};



const cartHistory = async function () {

  const loadingKey = 'cartHistory';
  this.loadingAdd(loadingKey);

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'ecomm/carrello/vedi-tutti',
    cacheAge: null,
    params: { c_utente: this.dataUserDetails.c_utente },
    userToken: this.userToken,
  });

  if (result && result.data) {
    runInAction(() => {
      this.dataEcomm.cartHistory = result.data;
      this.loading = false;
    });
  }

  this.loadingRemove(loadingKey);

};



const handleTimeExpired = async function () {

  // Tricky workaround to be sure that cart validity has expired before check it
  setTimeout(async () => {
    const cartExist = await this.checkIfCartExist();
    if (!cartExist) {
      runInAction(() => {
        this.dataEcomm.cartTimeExpired = true;
      });

      const modal = toJS(this.modalItems);
      if (!modal.length) {
        this.openEcommModal('ModalCartCountdownTimerExpired');
      }
    }
  }, 1000);

};



const openEcommModal = function (modalName) { // modalName like ModalMyCustomName declared into src/application/modals.js
  runInAction(() => {
    this.modalOpen(modalName);
  });
}



export {
  productsList,
  orderAndFilterProductsList,
  productDetails,
  checkIfCartExist,
  getCartGuid,
  addToCart,
  initCart,
  initStoredCart,
  cartDetails,
  deleteRowFromCart,
  automaticDeleteCart,
  permanentlyDeleteCart,
  confirmCart,
  cartHistory,
  handleTimeExpired,
  getDestinazioneByCNomeEcomm,
  openEcommModal,
};
