import {runInAction, toJS} from "mobx";
import moment from "moment";

const getStatistiche = async function (year, user, acl_user)
{
    let _params = {
        anno: year,
        c_utente: user,
        acl_user: acl_user
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    return response.data;
}

const getAclUser = async function (params, options)
{
    let _params = {};

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/alc/utenti',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.alc_user = response.data;
        });
    }
};

const getLastOrderds = async function (params, options)
{
    let _params = {
        anno: moment().year()
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'ordine/last',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.topnordini = response.data;
        });
    }
}

/*const getStatisticheDashboard = async function (params, options)
{
    let _params = {
        periodo: params ? params : 'month1'
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/dashboard',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.dashboard = response.data;
        });
    }
};*/

/*const getQlikFatturato = async function (params, options)
{
    let anni = params.map((item) => {
        return item.label
    })

    let _params = {
        anno: anni,
        cliente: this.dataStatistics.acl_user_selected || null
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/qlik/fatturato',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.fatturato = response.data;
        });
    }
}*/

/*const getQlikFatturatoClassificazione = async function (params, options)
{
    let _params = {
        anno: [params.label],
        cliente: this.dataStatistics.acl_user_selected || null
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/qlik/classificazione',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.classificazione = response.data;
        });
    }
}*/

/*const getQlikOrdinato = async function (params, options)
{
    let _params = {
        anno: [moment().year()]
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/qlik/ordinato',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.ordinato = response.data;
        });
    }
}*/

/*const getPortafoglioOrdini = async function (params, options)
{

    let _params = {
        anno: moment().year()
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/portafoglio',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.portafoglio = response.data;
        });
    }
};*/

export {
    getStatistiche,
    getAclUser,
    getLastOrderds,
    //getStatisticheDashboard
    //getQlikFatturato,
    //getQlikFatturatoClassificazione,
    //getQlikOrdinato,
    //getPortafoglioOrdini
}
