import langstore from '../../system/i18n/translator.js';

const numberFormatter = new Intl.NumberFormat('it-IT', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormat = (priceInput = 0 ,currency = 'EUR' ,excludeCurrency= false) => {
    const price = Number(priceInput || 0) || 0;
    const formattedPrice = numberFormatter.format(price);

    if (excludeCurrency) return formattedPrice;

    return `${formattedPrice} ${langstore.t(`currency_${currency}`, currency)}`
}

export {currencyFormat}
