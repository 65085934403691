import langstore from "../system/i18n/translator.js";

const routes = [
  /** ANAGRAFICA */
  {
    id: "clienti",
    path: "/clienti(/page/:pageNumber)",
    content: "ClientiArchivio",
    action: "clientlist",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Punti vendita",
  },
  {
    id: "clienti-dettaglio",
    path: "/clienti/dettaglio/:idClient",
    content: "ClientiDettaglio",
    action: "clientdetail",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Dettaglio Punto Vendita",
    titleKey: "section_clienti_dettaglio",
  },

  /** ORDINI */
  {
    id: "ordini-archivio",
    path: "/ordini",
    content: "OrdiniArchivio",
    action: "portafoglioordini",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Ordini",
  },
  {
    id: "ordini-attivi",
    path: "/ordini/attivi",
    content: "OrdiniAttivi",
    action: "orders",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "ordini-campioni",
    path: "/ordini/campione",
    content: "OrdiniCampioni",
    action: "ordiniCampioni",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "ordini-e-sostituzioni-con-note-da-leggere",
    path: "/ordini/note-da-leggere",
    content: "ArchiviOrdiniESostituzioniConNoteDaLeggere",
    action: "archivioNoteDaLeggere",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Note da leggere",
    titleKey: "note_da_leggere",
  },
  {
    id: "ordini-e-sostituzioni-da-confermare",
    path: "/ordini/da-confermare",
    content: "ArchiviOrdiniESostituzioniDaConfermare",
    action: "archivioDaConfermare",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Ordini da confermare",
    titleKey: "ordini_da_confermare",
  },
  {
    id: "otp-co",
    path: "/ordini/da-confermare/verifica/:language/:token",
    content: "ConfermaOtpRichiestaConfermaOrdine",
    action: "otpCheckRetrieveDetails",
    unproctected: true,
    navigation: null,
  },
  {
    // for backwards compatibility with firs url version without language support
    id: "otp-co-legacy",
    path: "/ordini/da-confermare/verifica/:token",
    content: "ConfermaOtpRichiestaConfermaOrdine",
    action: "otpCheckRetrieveDetails",
    unproctected: true,
    navigation: null,
  },
  {
    id: "opt-aap",
    path: "/aap/da-confermare/verifica/:language/:token",
    content: "ConfermaOtpAap",
    action: "otpCheckRetrieveDetails",
    unproctected: true,
    navigation: null,
  },
  {
    id: "noteassistenza",
    path: "/ordini/noteassistenza",
    content: "OrdiniNoteAssistenza",
    action: "ordiniNoteAssistenza",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "garanziedaattivare",
    path: "/ordini/garanzie-da-attivare",
    content: "OrdiniGaranzieDaAttivare",
    action: "ordiniGaranzieDaAttivare",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Garanzie da Attivare",
    titleKey: "",
  },
  {
    id: "ordini-detail",
    path: "/ordini/dettaglio/:orderYear/:orderNumber/:orderId",
    content: "OrdiniDettaglio",
    action: "orderdetail",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "ordini-tickets",
    path: "/ordini/dettaglio/:orderYear/:orderNumber/:orderId/tickets",
    content: "OrdiniTickets",
    action: "ordertickets",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "eldom-out-of-stock",
    path: "/ordini/elettrodomestici-non-disponibili/:token",
    content: "ConfermaEldomOutOfstock",
    action: "eldomOutOfStockRetrieveDetails",
    unproctected: true,
    navigation: null,
  },

  /** PREVISIONE SPEDIZIONI */
  {
    id: "shipping-forecast",
    path: "/previsionespedizioni",
    content: "PrevisioneSpedizioniArchivio",
    action: "previsioneSpedizioni",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
    title: "",
  },

  /** POST VENDITA */
  {
    id: "segnalazioni",
    path: "/post-vendita/segnalazioni",
    content: "ReclamiArchivio",
    action: "portafoglioreclami",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  /*{
        id: "reclami",
        path: "/reclami",
        content: "ReclamiArchivio",
        action: "portafoglioreclami",
        navigation: "NavigationTitle",
        sidebar: "SidebarMenu",
        titleString: "Reclami",
    },*/
  {
    id: "sostituzioni",
    path: "/post-vendita/sostituzioni",
    content: "SostituzioniArchivio",
    action: "sostituzioni",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  /*{
        id: "sostituzioni-attive",
        path: "/sostituzioni/attive",
        content: "SostituzioniAttive",
        action: "sostituzioniAttive",
        navigation: "NavigationTitle",
        sidebar: "SidebarMenu",
        titleString: "",
        titleKey: "",
    },*/
  {
    id: "autorizzazionireso",
    path: "/post-vendita/autorizzazioni-reso",
    content: "AutorizzazioniReso",
    action: "autorizzazioniResoList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "accredito-senza-reso",
    path: "/post-vendita/accredito-senza-reso",
    content: "FattureNoteAccredito",
    action: "paymentList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "noteaccredito",
    path: "/fatture/noteaccredito",
    content: "FattureNoteAccredito",
    action: "paymentList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "reclami-detail",
    path: "/post-vendita/segnalazioni/dettaglio/:reclamoId",
    content: "ReclamiDettaglio",
    action: "dettaglioReclamo",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "nuovoreclamo",
    path: "/post-vendita/nuovasegnalazione/:orderYear/:orderNumber/:orderId",
    navigation: "NavigationTitle",
    content: "ReclamiNuovoReclamo",
    action: "orderdetail",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** ECOMMERCE **/
  {
    id: "listaprodotti",
    path: "/ecommerce/lista-prodotti",
    content: "ListaProdotti",
    action: "productsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Lista prodotti",
    titleKey: "lista_prodotti",
  },
  {
    id: "dettaglioprodotto",
    path: "/ecommerce/dettaglio/:productId",
    content: "DettaglioProdotto",
    action: "productDetails",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Dettaglio Prodotto",
    titleKey: "dettaglio_prodotto",
  },
  {
    id: "carrello",
    path: "/ecommerce/carrello/:guid",
    content: "Carrello",
    action: "initCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello",
    titleKey: "carrello",
  },
  {
    id: "storicocarrelli",
    path: "/ecommerce/storico-carrelli",
    content: "StoricoCarrelli",
    action: "cartHistory",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Storico carrelli",
    titleKey: "storico_carrelli",
  },
  {
    id: "storicocarrellodetatglio",
    path: "/ecommerce/storico-carrello/:guid",
    content: "StoricoCarrelloDettaglio",
    action: "initStoredCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Storico carrello",
    titleKey: "storico_carrello",
  },

  /** COMMERCIALE */
  {
    id: "commlelistaprodottimarketing",
    path: "/commerciale/richiesta-marketing/lista-prodotti",
    content: "CommleListaProdottiMarketing",
    action: "commleMarketingInitProductsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Richiesta prodotti marketing",
    titleKey: "lista_prodotti_marketing",
  },
  {
    id: "commlecarrellomarkting",
    path: "/commerciale/richiesta-marketing/carrello/:guid",
    content: "CommleCarrelloMarketing",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello prodotti marketing",
    titleKey: "carrello",
  },
  {
    id: "commlelistaprodotticampionarioante",
    path: "/commerciale/richiesta-ante/lista-prodotti",
    content: "CommleListaProdottiCampionarioAnte",
    action: "commleCampionarioAnteInitProductsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Richiesta ante fuori campionario",
    titleKey: "lista_prodotti_campionario_ante",
  },
  {
    id: "commlecarrellocampionarioante",
    path: "/commerciale/richiesta-ante/carrello/:guid",
    content: "CommleCarrelloAnte",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello prodotti campionario ante",
    titleKey: "carrello",
  },
  {
    id: "commlelistacontratticampionari",
    path: "/commerciale/richiesta-campionari/lista-contratti",
    content: "CommleListaContrattiCampionari",
    action: "commleCampionariInitContractsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Richiesta prodotti campionario",
    titleKey: "lista_contratti_campionari",
  },
  {
    id: "commlelistaprodotticampionari",
    path: "/commerciale/richiesta-campionari/lista-prodotti/contratto/:contractId",
    content: "CommleListaProdottiCampionari",
    action: "commleCampionariInitProductsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Lista prodotti campionari",
    titleKey: "lista_prodotti_campionari",
  },
  {
    id: "commlecarrellocampionari",
    path: "/commerciale/richiesta-campionari/carrello/:guid",
    content: "CommleCarrelloCampionari",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello prodotti campionario",
    titleKey: "carrello",
  },
  {
    id: "commlelistaordinioggettistica",
    path: "/commerciale/richiesta-oggettistica/lista-ordini",
    content: "CommleListaOrdiniOggettistica",
    action: "commleOggettisticaInitOrdersList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Richiesta kit oggettistica",
    titleKey: "lista_ordini_oggettistica",
  },
  {
    id: "commlelistaprodottioggettistica",
    path: "/commerciale/richiesta-oggettistica/lista-prodotti",
    content: "CommleListaProdottiOggettistica",
    action: "commleOggettisticaInitProductsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Lista prodotti kit oggettistica",
    titleKey: "lista_proddotti_oggettistica",
  },
  {
    id: "commlecarrellooggettistica",
    path: "/commerciale/richiesta-oggettistica/carrello/:guid",
    content: "CommleCarrelloOggettistica",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello kit oggettistica",
    titleKey: "carrello",
  },
  {
    id: "commlecarrelloparzialeoggettistica",
    path: "/commerciale/richiesta-oggettistica/carrello/:guid/parziale/:ordcli",
    content: "CommleCarrelloParzialeOggettistica",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello parziale kit oggettistica",
    titleKey: "carrello",
  },
  {
    id: "commlelistaprodottiomaggio",
    path: "/commerciale/richiesta-omaggi/lista-prodotti",
    content: "CommleListaProdottiOmaggio",
    action: "commleOmaggiInitProductsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Richiesta prodotti omaggio",
    titleKey: "lista_prodotti_omaggio",
  },
  {
    id: "commlecarrelloomaggi",
    path: "/commerciale/richiesta-omaggi/carrello/:guid",
    content: "CommleCarrelloOmaggi",
    action: "commleInitCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Carrello prodotti omaggio",
    titleKey: "carrello",
  },
  ,
  {
    id: "commlelistaordinicompleta",
    path: "/commerciale/storico-ordini",
    content: "CommleCarrelloStoricoOrdini",
    action: "commleInitStoredCart",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "I miei ordini",
    titleKey: "carrello",
  },

  /** AMMINISTRAZIONE */
  {
    id: "fatture",
    path: "/fatture",
    content: "FattureArchivio",
    action: "invoiceList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "ddt",
    path: "/ddt",
    content: "DdtArchivio",
    action: "ddtList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
  },

  /** EXPO */
  {
    id: "expo",
    path: "/expo",
    content: "Expo",
    action: "orderExpo",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** PROMO */
  {
    id: "promozioni",
    path: "/promozioni",
    content: "PromozioniArchivio",
    //action: "promotionsList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** VIRTUAL TOUR */
  {
    id: "virtualtour_scorze",
    path: "/virtual-tour-scorze",
    content: "VirtualTourScorze",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "virtualtour_milano",
    path: "/virtual-tour-milano",
    content: "VirtualTourMilano",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },

  /** AREA TECNICA */
  {
    id: "finiture",
    path: "/finiture",
    content: "Finiture",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "finiture-ante",
    path: "/finiture/ante",
    content: "FinitureAnte",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "finiture-top",
    path: "/finiture/top",
    content: "FinitureTop",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "finiture-ante-modello",
    path: "/finiture/ante/:modelId",
    content: "FinitureAnteModello",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "schede-montaggio",
    path: "/schede-montaggio",
    content: "SchedeMontaggioHome",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "getSchedeMontaggio",
    titleString: "",
    titleKey: "",
  },
  {
    id: "schede-montaggio-category",
    path: "/schede-montaggio/categoria/:assetId",
    content: "SchedeMontaggioCategory",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "getSchedeMontaggioCategory",
    titleString: "",
    titleKey: "",
  },
  {
    id: "schede-montaggio-pdf",
    path: "/schede-montaggio/pdf/:assetId",
    content: "SchedeMontaggioPdf",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "schede-montaggio-video",
    path: "/schede-montaggio/video/:assetId",
    content: "SchedeMontaggioVideo",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "manuali-tecnici",
    path: "/manuali-tecnici",
    content: "ManualiTecnici",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "manuali-tecnici-category",
    path: "/manuali-tecnici/categoria/:assetId",
    content: "ManualiTecniciCategory",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "manuali-tecnici-pdf",
    path: "/manuali-tecnici/pdf/:assetId",
    content: "ManualiTecniciPdf",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "manuali-tecnici-video",
    path: "/manuali-tecnici/video/:assetId",
    content: "ManualiTecniciVideo",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "manuali-tecnici-gallery",
    path: "/manuali-tecnici/gallery/:assetId",
    content: "ManualiTecniciGallery",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "",
    titleString: "",
    titleKey: "",
  },
  {
    id: "scheda-prodotto",
    path: "/scheda-prodotto",
    content: "SchedaProdotto",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    action: "getSchedaProdotto",
    titleString: "",
    titleKey: "",
  },

  /** AREA SOFTWARE */
  {
    id: "requisiti-software",
    path: "/requisiti-software",
    content: "RequistiSoftware",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "metron-installer",
    path: "/metron-installer",
    content: "MetronInstaller",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** STATISTICHE */
  {
    id: "statistiche",
    path: "/statistiche",
    content: "Statistiche",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "lista-utenti",
    path: "/lista-utenti",
    content: "ListaUtenti",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Lista Utenti",
    titleKey: "lista_utenti",
  },

  /*** ACADEMY **********/
  /****** OLD VERSION ***/
  {
    id: "academy",
    path: "/academy",
    content: "Academy",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /****** NEW VERSION ***/
  {
    id: "videoacademy",
    path: "/video-academy",
    content: "VideoAcademy",
    action: "videoAcademyList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Elenco Video",
    titleKey: "video_academy",
  },

  {
    id: "videoacademydettaglio",
    path: "/video-academy/:videoId",
    content: "VideoAcademyDettaglio",
    action: "videoDetails",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Dettagli Video",
    titleKey: "video_academy_dettaglio",
  },

  {
    id: "videoacademycategoria",
    path: "/video-academy/categoria/:categoryId",
    content: "VideoAcademyCategoria",
    action: "videoAcademyCategory",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "Categoria video",
    titleKey: "video_academy_category",
  },

  /** AREA DOWNLOAD */
  {
    id: "area_download2",
    path: "/download",
    content: "AreaDownload",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "area_download2-pdf",
    path: "/download/pdf/:assetId",
    content: "AreaDownloadPdf",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** SUPPORTO */
  {
    id: "recapitiServizi",
    path: "/recapiti-e-servizi",
    content: "RecapitiEServizi",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },

  /** TODO deprecated because duplicated due to customer hub merging **/
  {
    id: "recapitiServiziArredo3DEPRECATED",
    path: "/arredo3-recapiti",
    content: "RecapitiEServizi",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "recapitiServiziDEPRECATED",
    path: "/recapitiservizi",
    content: "RecapitiEServizi",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "recapitiServiziBerloniDEPRECATED",
    path: "/berloni-recapiti",
    content: "RecapitiEServizi",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  /** TODO END  **/
  {
    id: "companyinformation",
    path: "/informative-aziendali",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "CompanyInformation",
    titleKey: "",
    titleString: "",
  },
  {
    id: "generalsellcondition",
    path: "/generalsellcondition",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "GeneralSellCondition",
    titleKey: "",
    titleString: "",
  },
  {
    id: "generalsellconditionberloni",
    path: "/condizioni-generali-berloni",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "GeneralSellCondition",
    titleKey: "",
    titleString: "",
  },
  {
    id: "tutorial",
    path: "/tutorial",
    content: "Tutorial",
    action: "tutorial",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "assistenza",
    path: "/assistenza",
    content: "Assistenza",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  {
    id: "booking",
    path: "/booking",
    content: "Booking",
    action: "",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleString: "",
    titleKey: "",
  },
  /** NEWSLETTER */
  {
    id: "newsletter_crea",
    path: "/newsletter-video-academy/crea",
    content: "CreateNewsletter",
    action: "videoAcademyList",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleKey: "newsletter_video_academy",
    titleString: "Newsletter video academy",
  },
  {
    id: "newsletter_lista",
    path: "/newsletter-video-academy/lista",
    content: "ListNewsletter",
    action: "getNewsletters",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleKey: "newsletter_video_academy",
    titleString: "Newsletter video academy",
  },
  {
    id: "newsletter_iscritti",
    path: "/newsletter-video-academy/iscritti",
    content: "NewsletterSubscribers",
    action: "getNewsletterSubscribers",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleKey: "newsletter_subscribers",
    titleString: "Iscritti alla newsletter academy",
  },
  {
    id: "videoacademysendstatus",
    path: "/newsletter-video-academy/stato-invio/:idNewsletter",
    content: "NewsletterSendState",
    action: "statusInvioNewsletter",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleKey: "status_invio_newsletter",
    titleString: "Stato invio newsletter",
  },
  {
    id: "newsletter_modifica",
    path: "/newsletter-video-academy/modifica/:idNewsletter",
    content: "EditNewsletter",
    action: "getNewsletterForEdit",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    titleKey: "newsletter_video_academy",
    titleString: "Newsletter video academy",
  },

  /** ALTRO */
  {
    id: "tweb_testata",
    path: "/tweb/testata",
    action: "twebredirect",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Loading",
    title: "",
  },
  {
    id: "tweb_ordinisospesi",
    path: "/tweb/ordinisospesi",
    action: "twebredirect",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Loading",
    title: "",
  },
  {
    id: "tweb_ordiniconfermati",
    path: "/tweb/ordiniconfermati",
    action: "twebredirect",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Loading",
    title: "",
  },
  {
    id: "gtlfviewer",
    path: "/3dview/:orderYear/:orderNumber/:orderId",
    content: "GtlfViewer",
    action: "view3dModel",
    navigation: "",
    sidebar: "",
    titleString: "",
    title: "",
  },
];

export default routes;
