import * as orderEvents from "../../src/application/computed/orderEvents.js";
import langstore from "../system/i18n/translator";

const state = {
    dataOrderStates: [],

    externalConfig: {},
    dataEcomm: {},

    attachments: [],

    dataCommerciale: {
        userRole: '',
        cartLoading: true,
        models: [],
        filters: {},
        marketing: {
            client: {},
            products: {},
            cart: {},
            params: {},
        },
        ante: {
            client: {},
            products: {},
            cart: {},
            params: {},
        },
        campionari: {
            client: {},
            contracts: {
                selected: null,
                list: {}
            },
            products: {},
            cart: {},
            params: {},
        },
        oggettistica: {
            client: {},
            orders: {
                selected: [],
                active: '',
                list: {}
            },
            products: {},
            cart: {},
            params: {},
        },
        omaggi: {
            client: {},
            products: {},
            cart: {},
            params: {},
        },
        all: {
            client: {},
            orders: {
                selected: [],
                list: {}
            },
        },
    },

    dataNewsletter: {},
    dataEventiOrdine: null,
    dataEventiNotifiche: [],

    dataClients: [],
    dataClientsParams: [],
    dataClient: {},

    dataContacts: [],
    dataContactsParams: [],

    dataOrders: [],
    dataOrdersParams: {},
    dataOrdersClient: [],
    dataOrder: {},
    dataOrderVestizione: {},
    dataOrderReclami: [],
    dataNoteTecnicheDashboard: {
        loading: true,
        messaggi_da_leggere: 0,
        messaggi_da_gestire: 0
    },
    dataOrdersToConfirmDashboard: {
        loading: true,
        count: 0
    },
    dataWarrantiesToBeActivatedDashboard: {
        loading: true,
        count: 0
    },
    dataAcademyVideoDashboard: {
        loading: true,
        count: 0
    },

    dataOrderSets: [],

    dataOrderRelations: {},

    dataTickets: {},
    dataTicket: {
        attachments: [],
    },
    noteMarkedAsManaged: false,

    data3dviewer: {},
    dataAutorizzazioni: {},


    dataInvoices: [],
    dataInvoicesParams: {},
    dataInvoicesParams2: {},
    dataInvoicePreview: "",
    dataInvoicesDetail: {},

    dataPayments: [],
    dataPaymentsParams: {},

    dataAutorizzazioniReso: [],
    dataAutorizzazioniResoParams: {},

    dataUserDetails: {},

    dataDDT: [],
    dataDDTParams: {},

    dataSostituzioni: [],
    dataSostituzioniAttive: [],
    //dataSostituzioniParams: {},

    dataOrdiniCampioni: [],
    dataOrdiniNoteAssistenza: [],
    dataOrdiniExpo: [],

    dataPortafoglioordini: [],

    dataPortafoglioreclami: [],

    dataReclamo: {
        section: 1,
        attachments: [],
        rows: [],
        note: "",
        referent: "",
        referent_email: "",
        referent_tel: ""
    },

    // Pim
    dataSchedeMontaggioCategorie: [],
    dataSchedeMontaggioCategoria: [],
    dataSchedeMontaggioManuali: [],
    dataSchedaProdotto: [],
    dataPromoDetail: [],

    // info file delle pratiche

    dataDocumentActive: [],
    dataDocumentInfo: {},
    ordersetsParams: {},

    filters_open: false,

    filterBlocked: false,

    // Promozioni
    dataPromo: {
        data: [],
        pim: [],
    },

    corsiDashboard: [
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
        {
            title: "01_Webinar Metron_INTRODUZIONE"
        },
    ],

    eldomOutOfStock: {},

    verificaOtp: {
        type: "",
        otp: "",
        nomeRiferimento: "",
        nomeCliente: "",
        idOrdine: "",
        idReclamo: "",
        statoRisposta: "",
        statoRecord: "",
        chiaveOutput: "",
        userResponse: "",
        rejectNotes: "",
    },

    // Statistiche
    dataStatistics: {
        fatturato: [],
        classificazione: [],
        ordinato: [],
        portafoglio: [],
        dashboard: [],
        topnordini: [],
        last_messages: [],
        last_messages_loader: false,
        acl_user: [],
        acl_user_selected: null,
        tot_fatturato: 0
    },
    // List of debounced fields
    debounces: [],

    // Download file
    download_enable: false,
    xml_enable: false,

    // Config ordini
    configOrdini: {
        raggruppaPerSpedizione: -1,
    },

    warranty_enable: null,
    edit_warranty_email: null,

    infoStatiOrdineAttivo: [],

    suborder_visibility: false,

    idDownloading: false,

    comunicazioniOnSync: false,

    dataReclamoDettaglio: null,

    warrantyLoading: false,

    total_size: 0,

    flag_reclamo: false,

    confirm_warranty: false,

    gruppi_portale: [],

    loading: false,

    tooltip: false,

    range_selected: [],

    dataAssistenza: {
        nome: "",
        cognome: "",
        email: "",
        telefono: "",
        sezione: "",
        allegati: [],
        note: "",
    },

    showRelazioni: false,

    dataGridOptions: {
        borders: false,
        rows: true,
        columns: false,
        alternation: false,
    },

    dataWarranty: {
        exists: "",
        email: "",
        name: "",
        lastname: "",
        lang: "",
        update: false,
    },

    paginationData: null,

    dataPim: {
        tutorial: [],
        guide: [],
        jwt: null,
        academy: { //  old
            list: null,
            asset: null,
        },
        videoAcademy: {
            list: null,
            categories: null,
            tags: null,
            singleVideo: null
        },
        area_download: {
            list: null,
        },
    },

    file: {
        name: "",
        url: "",
        data: null,
    },

    tipiOrdine: [
        { name: "Completamento", value: "C", key: "order_type1" },
        { name: "Cucina", value: "*", key: "order_type2" },
    ],

    statiOrdini: [
        { name: "Da inserire", value: 0, key: "stato_ordine0" },
        { name: "Da confermare", value: 10, key: "stato_ordine10" },
        { name: "In conferma", value: 15, key: "stato_ordine15" },
        { name: "Confermato", value: 20, key: "stato_ordine20" },
        { name: "In produzione", value: 30, key: "stato_ordine30" },
        { name: "Consegna in pianificazione", value: 35, key: "stato_ordine35" },
        { name: "Consegna pianificata", value: 40, key: "stato_ordine40" },
        { name: "Consegnato", value: 80, key: "stato_ordine80" },
        { name: "Da Fatturare", value: 50, key: "stato_ordine50" },
        { name: "Fatturato", value: 90, key: "stato_ordine90" },
    ],

    chiaviOrdinamento: [
        { name: langstore.t("order_date", "Data Ordine"), value: "data", key: "data", trad_key: "order_date" },
        { name: "Importo Totale", value: "prezzo", key: "prezzo", trad_key: "total_amount" },
        { name: "Consegna prevista", value: "settimana_consegna", key: "settimana_consegna", trad_key: "expected_delivery" },
    ],

    statiOrdiniAttivi: [
        { name: "Da inserire", value: 0, key: "stato_ordine0" },
        { name: "Da confermare", value: 10, key: "stato_ordine10" },
        { name: "In conferma", value: 15, key: "stato_ordine15" },
        { name: "Confermato", value: 20, key: "stato_ordine20" },
        { name: "In produzione", value: 30, key: "stato_ordine30" },
        { name: "Consegna in pianificazione", value: 35, key: "stato_ordine35" },
        { name: "Consegna pianificata", value: 40, key: "stato_ordine40" },
    ],

    statiGaranzia: [
        { name: "Non disponibile", value: -1, key: "not_available", cssClass: 'p-2 badge badge-disable' },
        { name: "Da attivare", value: 1, key: "available", cssClass: 'p-2 badge badge-engage' },
        { name: "Richiesta", value: 0, key: "requested", cssClass: 'p-2 badge badge-wip' },
        { name: "In attivazione", value: 10, key: "pending", cssClass: 'p-2 badge badge-wip' },
        { name: "Attivata", value: 20, key: "activated", cssClass: 'p-2 badge badge-done' },
    ],

    // DEPRECATED
    // sezioniPortale: [
    //     { value: 0, label: langstore.t("", "Homepage") },
    //     { value: 1, label: langstore.t("", "Ordini") },
    //     { value: 2, label: langstore.t("", "Previsione Spedizioni") },
    //     { value: 3, label: langstore.t("", "Reclami") },
    //     { value: 5, label: langstore.t("", "Sostituzioni") },
    //     { value: 6, label: langstore.t("", "Amministrazione") },
    //     { value: 7, label: langstore.t("", "Expo") },
    //     { value: 8, label: langstore.t("", "Promozioni") },
    //     { value: 9, label: langstore.t("", "Virtual Tour") },
    //     { value: 10, label: langstore.t("", "Area Tecnica") },
    //     { value: 11, label: langstore.t("", "Software") },
    //     { value: 12, label: langstore.t("", "Download") },
    //     { value: 13, label: langstore.t("", "Arredo3") },
    //     { value: 14, label: langstore.t("", "Guide e Supporto") },
    //     { value: 15, label: langstore.t("", "Statistiche") },
    //     { value: 16, label: langstore.t("", "Profilo") },
    //     { value: 17, label: langstore.t("", "Altro") },
    // ],

    tipiDocumenti: [
        { name: "Conferma d'ordine", value: '15', key: 'doc_type_15' },
        { name: "Fattura di vendita", value: '45', key: 'doc_type_45' },
        { name: "Garanzia Standard", value: 'GARANZSTD', key: 'doc_type_GRS' },
        { name: "Assistenza Conto Garanzia", value: 'RIEPASSIST', key: 'doc_type_RIEPASSIST' },
        { name: "Avviso di assistenza al pagamento", value: 'AAPAG', key: 'AAPAG' },
    ],

    workerUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.8.69/legacy/build/pdf.worker.min.mjs",

    statiReclamo: [
        { value: 1, name: 'Inviata', key: 'claim_status2', desKey: 'claim_status2_des', cssClass: 'badge badge-secondary', progressStatusValue: '25', statusColor: 'rgba(134,136,136,0.71)' },
        { value: 2, name: 'Immessa', key: 'claim_status3', desKey: 'claim_status3_des', cssClass: 'badge badge-info', progressStatusValue: '50', statusColor: 'rgba(59,59,89,0.82)' },
        { value: 3, name: 'In gestione', key: 'claim_status4', desKey: 'claim_status4_des', cssClass: 'badge badge-warning', progressStatusValue: '75', statusColor: 'rgba(168,150,93,0.79)' },
        { value: 4, name: 'Completata', key: 'claim_status5', desKey: 'claim_status5_des', cssClass: 'badge badge-success', progressStatusValue: '100', statusColor: 'rgba(112,169,128,0.8)' }
    ],

    _statiReclamo: [
        { value: 0, name: 'Immesso', key: 'claim_status0', cssClass: '', progressStatusValue: '25', statusColor: '' },
        { value: 10, name: 'In gestione', key: 'claim_status10', cssClass: '', progressStatusValue: '50', statusColor: '' },
        { value: 20, name: 'Confermato', key: 'claim_status20', cssClass: '', progressStatusValue: '75', statusColor: '' },
        { value: 30, name: 'Completato', key: 'claim_status30', cssClass: '', progressStatusValue: '90', statusColor: '' },
        { value: 40, name: 'Chiuso', key: 'claim_status40', cssClass: '', progressStatusValue: '100', statusColor: '' },
        { value: 50, name: 'Annullato', key: 'claim_status50', cssClass: '', progressStatusValue: '0', statusColor: '' },
        { value: 60, name: 'Inviato', key: 'claim_status60', cssClass: '', progressStatusValue: '10', statusColor: '' },
    ],

    chiaviOrdinamentoEcomm: [
        {name: 'Ultimi arrivi',     value:'dt_ins'},
        {name: 'Nome prodotto',     value:'pim_titolo'},
        {name: 'Prezzo',            value:'prz_netto'},
    ],

    chiaviOrdinamentoCommerciale: [
        {key: 'order_by_news',  name: "Novità", value:'data'},
        {key: 'order_by_product_name',  name: "Nome prodotto", value:'nome'},
        {key: 'order_by_price',  name: "Prezzo", value:'prezzo'},
    ],

    chiaviOrdinamentoAcademy: [
        {key: 'order_by_alpha', name: "Alfabetico", value: 'video.title' },
        {key: 'order_by_news', name: "Novità", value: 'dt_ins' },
        {key: 'order_by_recommended',   name: "Raccomandati", value: 'order' },
    ]
};

// Application state: computed values
const computedState = {
    ...orderEvents,
};

export {
    state,
    computedState
};
