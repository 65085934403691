import { action } from "mobx";
import langstore from "../system/i18n/translator.js";

const routes = [
  {
    id: "home",
    path: "/",
    navigation: "NavigationTitle",
    content: "Dashboard",
    sidebar: "SidebarMenu",
    title: "Index",
    titleKey: "dashboard_favorites",
    titleString: "Dashboard",
    action: "getDashboard",
  },
  {
    id: "userprofile",
    path: "/profile",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Profile",
    action: "userSubscriptionNewsletter",
    title: "Profilo utente",
    titleKey: "section_profile",
    titleString: "Profilo",
  },
  {
    id: "comunicazioni",
    path: "/comunicazioni",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Comunicazioni",
    action: "updateComunicazioniInit",
    title: "Comunicazioni",
    titleString: "Comunicazioni",
    titleKey: "comunications",
  },
  {
    id: "userlogin",
    path: "/login",
    action: "trySsoLogin",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Login",
    title: "Accedi",
    titleKey: "user_login",
    titleString: "Accedi al portale",
    unproctected: true,
  },
  {
    id: "resetpassword",
    path: "/passwordreset",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "PasswordReset",
    title: langstore.t("section_passworsreset", "Recupero password"),
    titleKey: "section_passworsreset",
    titleString: "Recupero password",
    unproctected: true,
  },
  {
    id: "resetexpiredpassword",
    path: "/password-expired",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "PasswordExpired",
    title: langstore.t("section_passwordexpired", "Recupero scaduta"),
    titleKey: "section_passwordexpired",
    titleString: "Recupero password",
    unproctected: true,
  },
  {
    id: "passwordresetrequestok",
    path: "/passwordresetrequestok",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "PasswordResetRequestOk",
    title: langstore.t("section_passworsreset", "Recupero password"),
    titleKey: "section_passworsreset",
    titleString: "Recupero password",
    unproctected: true,
  },
  {
    id: "downloads",
    path: "/downloads",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Downloads",
    title: "Download",
  },
  {
    id: "projection",
    path: "/projection",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Projection",
    title: "Proiezione",
  },
  {
    id: "loading",
    path: "/loading",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Loading",
    title: "Caricamento..",
    titleKey: "section_loading",
    titleString: "Caricamento..",
  },
  {
    id: "reference",
    path: "/reference",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Reference",
    title: "CSS reference",
    unproctected: true,
  },
  {
    id: "preview",
    path: "/preview/:n_anno_fattura/:c_numeratore_fattura/:n_fattura",
    title: "Anteprima fattura",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Preview",
    action: "invoicePreview",
  },
  {
    id: "previewfile",
    path: "/previewfile",
    title: "Anteprima fattura",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Previewfile",
  },
  {
    id: "previewfiledata",
    path: "/previewfile/:filedata",
    title: "Anteprima fattura",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Previewfile",
    action: "filePreview",
  },
  {
    id: "aclmanager",
    path: "/acl",
    title: "Gestione Utenti",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "Acl",
    action: "loadClientiDisponibili",
  },
  {
    id: "passwordreset",
    path: "/reimposta-password",
    navigation: "NavigationTitle",
    sidebar: "SidebarMenu",
    content: "PasswordResetProcedure",
    title: langstore.t("section_passworsreset", "Recupero password"),
    titleKey: "section_passworsreset",
    titleString: "Recupero password",
    unproctected: true,
    action: "startPasswordReset",
  },
];

export default routes;
