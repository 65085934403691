import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';
import NavigationTitle from '../system/components/navigationtitle.jsx';
import SidebarMenu from '../system/components/sidebarmenu.jsx';
import Projection from '../system/views/projection.jsx';

const Login = Loadable(() => import(/* webpackChunkName: "Login" */ "./views/login.jsx"));
const Profile = Loadable(() => import(/* webpackChunkName: "Profile" */ "./views/profile.jsx"));
const Comunicazioni = Loadable(() => import(/* webpackChunkName: "Comunicazioni" */ "./views/comunicazioni.jsx"));
const Acl = Loadable(() => import(/* webpackChunkName: "Comunicazioni" */ "./views/acl.jsx"));
const Downloads = Loadable(() => import(/* webpackChunkName: "Downloads" */ "./views/downloads.jsx"));
const Reference = Loadable(() => import(/* webpackChunkName: "Reference" */ "./views/reference.jsx"));
const PasswordReset = Loadable(() => import(/* webpackChunkName: "PasswordReset" */ "./views/passwordreset.jsx"));
const PasswordExpired = Loadable(() => import(/* webpackChunkName: "PasswordReset" */ "./views/passwordexpired.jsx"));
const PasswordResetProcedure = Loadable(() => import(/* webpackChunkName: "PasswordReset" */ "./views/passwordresetprocedure.jsx"));
const Preview = Loadable(() => import(/* webpackChunkName: "Preview" */ "./views/preview.jsx"));
const Previewfile = Loadable(() => import(/* webpackChunkName: "Preview" */ "./views/previewfile.jsx"));

let views = {
    Loading,
    SidebarMenu,
    NavigationTitle,
    Profile,
    Projection,
    Login,
    Comunicazioni,
    Acl,
    Downloads,
    Reference,
    PasswordReset,
    PasswordExpired,
    Preview,
    Previewfile,
    PasswordResetProcedure
}

export default views;
