import * as common from '../system/computed/common.js';


let computedState = {
    ...common
}

let state = {

    appReady: false,

    userToken: null,
    userService: null,
    userInfo: {
        c_utente: "",
        name: "",
        email: "",
        title: "",
        internal: false
    },

    internalSearchResults: {},

    userTokenRoot: null,
    userInfoRoot: {},
    userServiceRoot: null,

    bookmarks: [],
    notifications: [],
    formData: {},
    transientData: {},
    networkLoading: [],
    isOnline: true,

    downloads: [1, 2],

    enabledEmails: {},

    messages: [],
    messages_loader: false,

    infoCapigruppo: [],
    infoNumeratori: [],
    infoStatiOrdine: [],

    uiSidebarExpanded: null,
    uiGridOptionsExpanded: false,
    uiAreasExpanded: false,
    uiShowCustomer: false,
    uiViewMode: 'table',
    uiLoginEmail: "",
    uiLoginPassword: "",
    uiTableFontSize: 3,
    uiFilter: "tesy_normal",
    uiDocumentWidth: 100,
    uiContentWidth: 100,
    uiScrollInfo: 0,
    uiGridWidth: {},
    uiTouch: false,

    modalItems: [],
    menus: [],
    menusOpen: [],
    menusAcl: [],

    previewData: {},

    selfCliente: null,

    languages: [],
    language: 'it',
    route: "/",

    ssoLoginRunning: false,

    analytics: [],

    cacheParams: {},

    namespaceIndex: '',
    fullNamespace: '',
    networkDBStore: null,

    enable_routes: [],

    ultimo_run_ok: null,
    last_update: null,
    next_update: null,

    show_lang_list: false

}

export {state, computedState}
