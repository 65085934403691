// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import {extendObservable} from 'mobx';
import {Provider} from 'mobx-react';
import ReactGA from "react-ga4";
import version from '../../version';
import 'whatwg-fetch';
import "./fonts/Helvetica-Neue-Font/HNB/HNB.ttf";

// autorun and external events
import {persistentState, persistentHistory} from './autorun/persistence';
import routeSync from './autorun/routesync.js';
import statSync from './autorun/statsync.js';
import keyboardEvents from './autorun/keyboardevents.js';
import windowEvents from './autorun/windowevents.js';
import networkEvents from './autorun/networkevents.js';

// actions
import actions from '../system/actions.js';
import actionsCustom from '../../src/application/actions.js';

// state objects
import {state, computedState} from './state';
import {state as stateCustom, computedState as computedStateCustom} from '../../src/application/state';

// routes
import routes from '../system/routes'
import routesCustom from '../../src/application/routes';

// Base view
import Base from '../system/views/base.jsx';

// Translations
import langstore from '../system/i18n/translator.js';

// style
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'rc-tooltip/assets/bootstrap.css';

import './style.js'

import { ThemeProvider } from '@mui/material/styles';
import theme from '../system/mui-theme';


// devExtreme license
import config from 'devextreme/core/config';
let licenseKey;
try {
    licenseKey = require('../../devextreme-license.ts').licenseKey;
    if (!licenseKey) {
        throw new Error("License key non trovata: Assicurati di definire una chiave valida.");
    }
} catch (error) {
    console.error("Errore durante l'importazione della license key:", error.message);
}
config({ licenseKey });


(async () => {
    try {
        // TODO avoid curl and prefer load from file
        let configURL1 = "./config/config.json";
        let configURL2 = window.location.origin + "/config/config.json";
        let response = await fetch(configURL1, {cache: "reload"});
        if (response.status === 404) {
            response = await fetch(configURL2, {cache: "reload"});
        }
        let config = await response.json();
        console.log("V", config.app.version);

        /**
         Routes
         **/

        // Extend system routes with custom routes
        function extendRoute(initialRoute, ...routeArrays) {
            routeArrays.forEach(ra => {
                if (ra) {
                    ra.forEach(routeObj => {
                        const matchMap = initialRoute.map(fa => fa.id);
                        const currId = routeObj.id;
                        const matchingPosition = matchMap.indexOf(currId);

                        if (matchingPosition !== -1) {
                            initialRoute.splice(matchingPosition, 1);
                        }

                        initialRoute.push(routeObj)
                    })
                }
            })
        }

        extendRoute(routes, routesCustom);

        /**
         Generate default state
         **/
        Object.assign(state, stateCustom);
        Object.assign(computedState, computedStateCustom);

        /**
         Store definition
         **/

        class RootStore {

            constructor(config, state, computedState, routes) {

                /**
                 Non observable
                 **/

                this.config = config;
                this.routes = routes;
                this.feVersion = version;
                this.beVersion = null

                /**
                 Observable
                 **/

                Object.keys(computedState).forEach((key) => {
                    state[key] = computedState[key];
                })

                Object.keys(computedState).forEach((key) => {
                    Object.defineProperty(state, key, {
                        get: () => {
                            return computedState[key].apply(this);
                        }
                    });
                })

                extendObservable(this, state);

            }

        }


        /**
         Actions
         **/

        // Extend / overwrite with custom actions
        Object.assign(actions, actionsCustom);

        // Create mobx state object
        Object.keys(actions).forEach(function (key) {
            RootStore.prototype[key] = actions[key];
        });

        const rootstore = new RootStore(config, state, computedState, routes);
        if (config.development.loggableStore === true) {
            window.__store = rootstore;
        }

        /**
         Autorun effects; order is important
         **/

        persistentState(rootstore, config.cacheInterface);
        persistentHistory(rootstore);
        routeSync(rootstore, routes);
        statSync(rootstore);


        /**
         Additional events
         **/

        keyboardEvents(rootstore);
        windowEvents(rootstore);
        networkEvents(rootstore);


        /**
         GA4 initialization
         **/

        if (config.development.production && config.vendors.googleAnalyticsV4ID) {
            ReactGA.initialize(config.vendors.googleAnalyticsV4ID);
            ReactGA.send("pageview");
        }

        /**
         App ready
         **/
        rootstore.initCore();
        await rootstore.init();
        await rootstore.getBeVersion();


        rootstore.log("Rootstore inizializzato", "ROOT");

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('service-worker.js', {
                scope: '/'
            })
                .then(function (registration) {
                    console.debug('Registration successful, scope is:', registration.scope);
                })
                .catch(function (error) {
                    console.debug('Service worker registration failed, error:', error);
                });
        }

        /**
         App render
         **/

        const root = ReactDOM.createRoot(document.getElementById('root')); // Inizializza il root container
        root.render(
            <Provider
                langstore={langstore}
                rootstore={rootstore}>
                <ThemeProvider theme={theme}>
                    <Base/>
                </ThemeProvider>
            </Provider>
        );


    } catch (e) {
        console.log("Errore in main.jsx", e);
    }

})();
