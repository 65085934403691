import { runInAction, toJS } from 'mobx';

// close first modal in modals  array
const modalClose = function(){
  let modalItems = toJS(this.modalItems);
  modalItems.shift();
  runInAction( ()=> {
    this.modalItems = modalItems;
  });
}

// reset modals array
const modalCloseAll = function(size){
  runInAction( ()=> {
    this.modalItems = [];
  });
}

// push modal in modals array
const modalOpen = function(component,params, disableCloseOnBackClick = false){
  let modalItems = toJS(this.modalItems);
  modalItems.push({
    component: component,
    params: params,
    disableCloseOnBackClick: disableCloseOnBackClick
  })
  runInAction( ()=> {
    this.modalItems = modalItems;
  });
}

export { 
  modalClose,
  modalCloseAll,
  modalOpen
}