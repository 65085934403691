import {runInAction} from "mobx";
import ReactGA from "react-ga4";

const extractParents = function (object)
{
    let parents =  [];

    while (object && object.id){
        parents.push({'id': object.id, 'title': object.title});
        object = object.parent;
    }

    return parents.reverse();
}

const parentsToString = function (parents)
{
    let parents_string = '';

    if(parents && parents.length) {
        for (let i = 0; i < parents.length; i++) {
            parents_string = parents_string + parents[i].title + '|';
        }
    }

    return parents_string;
}

/** Schede di montaggio */
const getSchedeMontaggio = async function ()
{
  try {

    const clienteGruppoKey = Object.keys(this.config.clienteGruppo).find(key => this.config.clienteGruppo[key] === this.userInfo.c_cliente_gruppo);

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'brand',
        'entity': 'schedeMontaggio',
        'scope': 'categories',
        'options': {
          'id_folder': clienteGruppoKey !== undefined ? this.config.pim.schedeMontaggio['folderId' + clienteGruppoKey] : this.config.pim.schedeMontaggio.folderId
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    runInAction(() => {
      this.dataSchedeMontaggioCategorie = results?.data?.categories ?? null
    });

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getSchedeMontaggioCategory = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'brand',
        'entity': 'schedeMontaggio',
        'scope': 'category',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    runInAction(() => {
      this.dataSchedeMontaggioCategoria = category
    });

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "assembly-manuals",
          action: "show-category",
          label: `${this.language.toUpperCase()}|assembly-manuals|${parentsString}${category.title}|category`
        });
      }
    }

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getSchedeMontaggioPdf = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'brand',
        'entity': 'schedeMontaggio',
        'scope': 'pdf',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "assembly-manuals",
          action: "show-pdf",
          label: `${this.language.toUpperCase()}|assembly-manuals|${parentsString}${category.title}|pdf`
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getSchedeMontaggioVideo = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'brand',
        'entity': 'schedeMontaggio',
        'scope': 'video',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "assembly-manuals",
          action: "show-video",
          label: `${this.language.toUpperCase()}|assembly-manuals|${parentsString}${category.title}|video`
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

/** Manuali tecnici */
const getTechnicalManuals = async function (params, options)
{
  try {

    const clienteGruppoKey = Object.keys(this.config.clienteGruppo).find(key => this.config.clienteGruppo[key] === this.userInfo.c_cliente_gruppo);

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'master',
        'entity': 'manualiTecnici',
        'scope': 'categories',
        'options': {
          'id_folder': clienteGruppoKey !== undefined ? this.config.pim.technicalManuals['folderId' + clienteGruppoKey] : this.config.pim.technicalManuals.folderId
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    return results?.data?.categories ?? null;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getTechnicalManualsCategory = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'master',
        'entity': 'manualiTecnici',
        'scope': 'category',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config?.development?.production && this.config?.vendors?.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "technical-manuals",
          action: "show-category",
          label: `${this.language.toUpperCase()}|technical-manuals|${parentsString}${category.title}|category`,
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getTechnicalManualsPdf = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'master',
        'entity': 'manualiTecnici',
        'scope': 'pdf',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config?.development?.production && this.config?.vendors?.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "technical-manuals",
          action: "show-pdf",
          label: `${this.language.toUpperCase()}|technical-manuals|${parentsString}${category.title}|pdf`,
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getTechnicalManualsGallery = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'master',
        'entity': 'manualiTecnici',
        'scope': 'gallery',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config?.development?.production && this.config?.vendors?.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "technical-manuals",
          action: "show-gallery",
          label: `${this.language.toUpperCase()}|technical-manuals|${parentsString}${category.title}|gallery`,
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

const getTechnicalManualsVideo = async function (params, options)
{
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/entities',
      params: {
        'language': this.language,
        'origin': 'master',
        'entity': 'manualiTecnici',
        'scope': 'video',
        'options': {
          'id_categoria': parseInt(this.routeParamsCurrent.assetId)
        }
      },
      cacheAge: 180000, // 3 minutes
      userToken: this.userToken,
    });

    const category = results?.data?.category ?? null;

    if (category) {
      const parents = extractParents(category.parent);
      const parentsString = parentsToString(parents);

      if (this.config?.development?.production && this.config?.vendors?.googleAnalyticsV4ID) {
        ReactGA.event({
          category: "technical-manuals",
          action: "show-video",
          label: `${this.language.toUpperCase()}|technical-manuals|${parentsString}${category.title}|video`,
        });
      }
    }

    return category;

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message, error.id, 5000, "error");
  }
}

/**  Scheda prodotto */
const getSchedaProdotto = async function (params, options)
{
  try {

    const clienteGruppoKey = Object.keys(this.config.clienteGruppo).find(
      key => this.config.clienteGruppo[key] === this.userInfo.c_cliente_gruppo
    );

    const folderIds = clienteGruppoKey
      ? Object.values(this.config.pim.schedaProdotto[`folderId${clienteGruppoKey}`])
      : Object.values(this.config.pim.schedaProdotto.folderId);

    const fetchDataForFolder = async id => {
      const { data } = await this.dataLayer({
        url: `${this.config.paths.apiURL}pim/entities`,
        params: {
          'language': this.language,
          'origin': 'brand',
          'entity': 'schedaProdotto',
          'scope': 'product',
          'options': { id },
        },
        cacheAge: 180000, // 3 minuti
        userToken: this.userToken,
      });

      return data?.datasheet?.published ? data.datasheet : null;
    };

    const results = await Promise.all(folderIds.map(fetchDataForFolder));

    const allResults = results.filter(Boolean);

    runInAction(() => {
      this.dataSchedaProdotto = allResults.length > 0 ? allResults : null;
    });

  } catch (error) {
    console.error(error);
    this.notificationAdd(error.message || "Errore generico", error.id || "unknown", 5000, "error");
  }
};


const getModelli = async function (params)
{
    let _params = {
        alsounpub: 1,
        lang: this.language
    };

    if (params) {
        _params.model = params
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/modelli',
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    return response.data[0]
}

const getMateriali = async function (params)
{
    let _params = {
        lang: this.language
    };

    if (params === 'ante') {
        _params.ante = 1;
    }
    if (params === 'top') {
        _params.top = 1;
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/materiali',
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    return response.data[0]
}

const getColori = async function (params)
{

}

const getFinishes = async function (params, route)
{
    let _params = {
        lang: this.language
    };

    if (params && params.model) {
        _params.model = params.model
    }

    if (params && params.all) {
        _params.all = 1
    }

    if (params && params.page) {
        _params.page = params.page
    }

    if (params && params.material) {
        _params.material = params.material
        if (route === 'top')
            _params.all = 1
    }

    if (params && params.color) {
        _params.color = params.color
    }

    if (params && params.title) {
        _params.title = params.title
        _params.all = 1
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/' + route,
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    let model = _params.model ? '|'+_params.model :'';

    // console.log(this.language.toUpperCase()+'|Finiture|'+route+model);

    if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
            category: "finishes", action: "show-finishes", label: this.language.toUpperCase()+'|finishes|'+route+model
        });
    }

    return response.data[0]
}

export {
    getSchedeMontaggio,
    getSchedeMontaggioCategory,
    getSchedeMontaggioPdf,
    getSchedeMontaggioVideo,
    getTechnicalManuals,
    getTechnicalManualsCategory,
    getTechnicalManualsPdf,
    getTechnicalManualsGallery,
    getTechnicalManualsVideo,
    getSchedaProdotto,
    getModelli,
    getMateriali,
    getColori,
    getFinishes,
    extractParents,
    parentsToString
}
