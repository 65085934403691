
export default function(store){

    document.addEventListener('keydown', function(evt){
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key == "Escape" || evt.key == "Esc");
        } else {
            isEscape = (evt.keyCode == 27);
        }
        if (isEscape) {
            store.uiViewMode = 'table';
        }
        if(isEscape && store.modalItems[0]?.disableCloseOnBackClick === false){
            store.modalClose();
        }
    })

}

