import {runInAction} from "mobx";
import langstore from "System/i18n/translator";

const getUsersList = async function (params= {})
{
  try {
    const response = await this.dataLayer({
      url: this.config.paths.apiURL + 'utenti/lista',
      cacheAge: 180000, // 3 minutes or -1 to disable cache
      params: params,
      userToken: this.userToken,
    });

    return response.data;

  } catch (error) {
    this.notificationAdd('ERR: ' + error.message, error.id, 10000, 'error');
  }
};

const getClientsByUser = async function (params)
{
  try {
    const response = await this.dataLayer({
      url: this.config.paths.apiURL + 'utenti/clienti',
      cacheAge: 180000, // 3 minutes or -1 to disable cache
      params: {
        c_utente: params.c_utente
      },
      userToken: this.userToken,
    });

    if (response && response.data) {
      runInAction(() => {
        this.loading = false;
        this.modalOpen("ModalClientsByUserList", {...params, ...response});
      });
    } else {
      this.notificationAdd(langstore.t('', 'Error'), '', null, 'error');
    }

  } catch (error) {
    this.notificationAdd('ERR: ' + error.message, error.id, 10000, "error");
  }
};

export {
  getUsersList,
  getClientsByUser
}
