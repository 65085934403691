import React from 'react'
import {inject, observer} from 'mobx-react';
import ContextMenu from 'devextreme-react/context-menu';

class Impersonification extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            user: null
        };

        this.logoutToImpersonatingUser = this.logoutToImpersonatingUser.bind(this);
        this.itemClick = this.itemClick.bind(this);
    }

    logoutToImpersonatingUser(c_utente) {
        this.props.rootstore.generateSSOOnBehalfOf(c_utente);
    }

    itemClick(e) {
        this.logoutToImpersonatingUser(this.props.rootstore.userInfo.impersonatingFrom)
    }

    render() {
        const {rootstore, langstore} = this.props;

        const items = [
            {text: 'Esci', icon: 'runner'}
        ];

        return (
            <React.Fragment>
                <div className="px-3 ">
                    {/*<p className="t1" id="impersonification_option">Accesso eseguito da: <b>{rootstore.userInfo.impersonatingFrom}</b></p>*/}
                    <div className="t1"><b>{rootstore.userInfo.impersonatingFrom}</b></div>
                    <button
                        type="button"
                        className="ts_button2 btn_color4"
                        onClick={() => this.logoutToImpersonatingUser(rootstore.userInfo.impersonatingFrom)}>
                        <i className="fas fa-sign-out-alt"/>
                        Logout agente
                    </button>
                    {/*<ContextMenu
                        showEvent="dxclick"
                        dataSource={items}
                        width={200}
                        target="#impersonification_option"
                        onItemClick={this.itemClick} />*/}
                </div>
            </React.Fragment>
        )
    }

    render_OLD() {
        const {rootstore, langstore} = this.props;

        return (
            <div className="row mr-3">
                <div className="col-12">
                    <div className="row align-items-center">
                        <p className="t1">Accesso eseguito da:<br/><b>{rootstore.userInfo.impersonatingFrom}</b></p>
                        <button
                            type="button"
                            className="ts_button1 btn_color4"
                            onClick={() => this.logoutToImpersonatingUser(rootstore.userInfo.impersonatingFrom)}>
                            <i className="fas fa-sign-out-alt"/>
                            Clicca qui per logout agente
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject("rootstore", "langstore")(observer(Impersonification));



      

