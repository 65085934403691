import {autorun, runInAction} from "mobx"
import {getParameterByName} from "../utilities/urls";

export default function (_this) {

    const cleanUpRoute = function() {
        let _routeToRedirect = _this.routeParamsCurrent.routeObject.path;
        if (_routeToRedirect.includes(':')) { // urls may contain also param like orderID or reclamoID and so need to claim up with this dirty trick
            _routeToRedirect = _this.route;
        }
        return _routeToRedirect;
    }

    // routes autorun
    autorun(() => {

        const userToken = _this.userToken;
        const userTokenRoot = _this.userTokenRoot;
        const routeParams = _this.routeParamsCurrent;
        const ssoToken = getParameterByName('ssoToken');
        const routePath = routeParams.routeObject && routeParams.routeObject.path;
        const ssoLogin = ssoToken && routePath === '/login';

        let routeCurrent = null;

        // SSO operations running. preventing standard checks
        if (_this.ssoLoginRunning) {
            _this.log("Routesync bloccato per login SSO", "WARNING");
            return;
        }

        // redirect to login is permission is insufficent
        if (!userToken && routeParams.unproctected !== true && !ssoLogin) {
            _this.log("Redirect a login perché non autenticato", "WARNING");
            const routeToRedirect = cleanUpRoute();
            _this.routeToRedirect(routeToRedirect);
            _this.routeChange("/login");
            return;
        }

        // redirect to user profile if trying to login while already logged
        if (userToken && routeParams.routeId === 'userlogin' && !ssoLogin) {
            let redirectTo = '/';
            // if (_this.routeRedirect && _this.userInfo.internal !== true) {  // don't remember why internal user was excluded from redirect, but this prevents a correct use of "commercial" features
            if (_this.routeRedirect) {
                redirectTo = _this.routeRedirect;
            }
            _this.log('Redirect da pagina login a ' + redirectTo + ' perché già autenticato', 'OK');
            _this.routeChange(redirectTo);
            return;
        }

        // redirect to index if trying to select service and service already selected
        if (userToken && _this.userService !== null && routeParams.routeId === 'userservice') {
            _this.log("Redirect da userservice, servizio già selezionato", "OK");
            _this.routeChange("/")
            return;
        }

        // redirect to index if wrong URL format
        if (_this.config.app.hashsegment && window.location.href.search(_this.config.app.hashsegment) < 0) {
            _this.log("Redirect per formato URL errato", "WARNING");
            _this.routeChange("/")
            return;
        }

        // TODO
        // redirect to user selection if root token is active and user token not selected
        if (userToken && userTokenRoot && (userToken === userTokenRoot)) {
            _this.routeChange("/projection");
            return;
        }

        // find route
        routeCurrent = routeParams.routeObject;

        // run actions
        if (routeCurrent && _this[routeCurrent.action] && (_this.appReady === true || routeParams.unproctected === true)) {
            _this.log("Azione: " + routeCurrent.action, "STORE");
            let routeAction = _this[routeCurrent.action];
            runInAction(() => {
                routeAction.call(_this, routeCurrent, routeCurrent.options || null);
            });
        }
    })
}
