import langstore from "../../system/i18n/translator";
import {runInAction} from "mobx";

const getWarrantiesToBeActivatedDashboard = async function () {
    try {

        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'ordine/garanzie-da-attivare',
            cacheAge: this.config.cacheAPI.default,
            params: {},
            userToken: this.userToken,
        });
        runInAction(() => {
            if (response) {
                this.dataWarrantiesToBeActivatedDashboard.count = response.data.ordini.length || 0;
                this.dataWarrantiesToBeActivatedDashboard.loading = false;
            }
        });

    } catch (error) {
        runInAction(() => {
            this.dataWarrantiesToBeActivatedDashboard.loading = false;
            this.dataWarrantiesToBeActivatedDashboard.count = 0;
        });
        console.error("Errore durante il recupero delle garanzie da attivare:", error);
    }
};

const orderGaranziaEmail = async function (params) {

    let ordine = {
        'n_anno_ordine': params.n_anno,
        'c_numeratore_ordine': params.c_numeratore,
        'n_ordine': params.n_ordine
    };

    if (!this.formData.garanziaLingua) {
        this.formData.garanziaLingua = 'it';
    }

    let user = {
        'ds_nome': this.formData.garanziaName,
        'ds_cognome': this.formData.garanziaLastname,
        'email': this.formData.garanziaEmail,
        'lingua': this.formData.garanziaLingua
    };

    let _params = {
        'user': user,
        'ordine': ordine
    };

    if (!user.email || typeof user.email !== "string" || user.email.indexOf("@") < 0) {
        this.notificationAdd(langstore.t("garanzia_email_error", "Email non valida"), "orderGaranziaEmailError", 5000, "error");
        this.warrantyLoading = false;
    }

    if (this.dataWarranty.update === true) {
        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'garanzia/update/email',
            cacheAge: null,
            params: _params,
            userToken: this.userToken,
            method: "PATCH"
        });

        runInAction(() => {
            if (response.status === 'OK') {
                this.notificationAdd(langstore.t("garanzia_email_update_success"), "orderGaranziaEmail", 5000, "success");
            }
        });
    }
    else {
        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'garanzia',
            cacheAge: null,
            params: _params,
            userToken: this.userToken
        });

        runInAction(() => {
            if (response.status === "OK") {
                this.notificationAdd(langstore.t("garanzia_email_success"), "orderGaranziaEmail", 5000, "success");
            }
        });
    }

    this.dataWarranty.update = false;
    this.formData.garanziaName = null;
    this.formData.garanziaLastname = null;
    this.formData.garanziaEmail = null;
    this.formData.garanziaLingua = null;
    this.confirm_warranty = false;
    this.loading = false
    this.modalCloseAll();
    this.routeReload();
};

const sendWarrantyNotification = async function (params)
{
    let _params = {
        n_anno: params.n_anno,
        c_numeratore: params.c_numeratore,
        n_ordine: params.n_ordine
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'garanzia/notification',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
        method: "POST"
    });

    runInAction(() => {
        if (response.status === "OK") {
            this.notificationAdd(langstore.t("warranty_notification_success"), "orderGaranziaEmail", 5000, "success");
        }
    });

    this.loading = false
}

export {
    getWarrantiesToBeActivatedDashboard,
    orderGaranziaEmail,
    sendWarrantyNotification
}
