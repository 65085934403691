import {runInAction, toJS} from "mobx";
import langstore from "../../system/i18n/translator";
import menuIcons from 'Application/menuIcons'

const internalUserSearchForCustomer = async function (searchPattern) {
    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'internal/search',
        cacheAge: 1,
        params: {
            search: searchPattern
        },
        userToken: this.userToken
    });

    if (response) {
        return response.data;
    }
};

const generateSSOOnBehalfOf = async function (c_utente) {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'internal/obosso',
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    if (response.data) {
        await this.trySsoLogin(response.data);
    }
};

const getGruppiPortale = async function (params) {
    let _params = {};

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'internal/gruppi',
        cacheAge: 1,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.gruppi_portale = response.data;
        })
    }
};

const getMenuInternal = async function(params)
{
    let _params = {
        userType: params
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'menu/internal',
        cacheAge: 1,
        params: _params,
        userToken: this.userToken
    });

    if (response && response.data && response.data.length > 0) {
        const menu = response.data

        const menuBuilder = (parentId, newId) => {
            var i = 0;
            const block = [];

            menu
                .sort((a, b) => {
                    if (a.n_ordinamento < b.n_ordinamento) {
                        return -1;
                    }
                    if (a.n_ordinamento > b.n_ordinamento) {
                        return 1;
                    }
                    return 0;
                })
                .forEach(m => {
                    let blockArray = [];

                    if (m.guid_item_padre !== parentId) {
                        return;
                    }

                    const children = menu.filter(mu => mu.guid_item_padre == m.guid);
                    const idMenu = newId ? `${newId}.${i + 1}` : `${i + 1}`;

                    //const initialSlash = m.c_tipoazione != 'ssoredirect' && m.c_tipoazione != 'externalurl';

                    const mappedItem = {
                        idMenu,
                        //path: `${initialSlash ? '/' : ''}${m.url}`,
                        path: '/'+m.url,
                        //actionType: m.c_tipoazione,
                        titleKey: `${m.c_chiave_traduzione}`,
                        titleString: `${m.ds_menu_item}`,
                        menu: `main`,
                        iconClass: menuIcons[m.c_menu_item],
                        //params: m.parametri
                    };

                    //this.enable_routes.push(`${initialSlash ? '/' : ''}${m.url}`);
                    this.enable_routes.push('/'+m.url);

                    if (newId) {
                        mappedItem.parent = newId;
                    }

                    blockArray.push(mappedItem);

                    if (children.length > 0) {
                        blockArray = [
                            ...blockArray,
                            ...menuBuilder(m.guid, idMenu)
                        ]
                    }

                    block.push(...blockArray);

                    i++;
                });

            return block
        }

        runInAction(() => {
            this.menus = menuBuilder(null);
        });
    }

    return null;
}

export {
    internalUserSearchForCustomer,
    generateSSOOnBehalfOf,
    getGruppiPortale,
    getMenuInternal
};