const menuIcons = {
  clienti: "fas fa-store-alt",
  ordini: "fas fa-cart-arrow-down",
  previsione_spedizioni: "fas fa-truck",
  reclami: "far fa-comment-dots",
  post_vendita: "far fa-comment-dots",
  sostituzioni: "fas fa-exchange-alt",
  amministrazione: "fas fa-piggy-bank",
  expo: "fas fa-store",
  promozioni: "fas fa-percentage",
  virtualtour: "fas fa-cube",
  area_tecnica: "fas fa-wrench",
  area_software: "fas fa-laptop",
  area_download: "fas fa-cloud-download-alt",
  area_download2: "fas fa-cloud-download-alt",
  arredo3: "fas fa-building",
  berloni: "fas fa-building",
  primacucine: "fas fa-building",
  assistenza: "fas fa-question-circle",
  statistiche: "fas fa-chart-line",
  booking: "fas fa-headset",
  academy: "fas fa-graduation-cap",
  newsletter: "fas fa-envelope",
  temporary_outlet: "fas fa-shopping-basket"
};

export default menuIcons;
