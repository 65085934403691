import Loadable from '@loadable/component';

const ModalMessage = Loadable(() => import(/* webpackChunkName: "Modals0" */ "./modals/modalmessage.jsx"));
const ModalDefault = Loadable(() => import(/* webpackChunkName: "Modals1" */ "./modals/modaldefault.jsx"));
const ModalReset = Loadable(() => import(/* webpackChunkName: "Modals3" */ "./modals/modalPasswordReset.jsx"));


let modals = {
    ModalMessage,
    ModalDefault,
    ModalReset
}

export default modals;
