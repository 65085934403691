import { runInAction } from 'mobx';
import ReactGA from "react-ga4";


const getJwt = async function (params) {
    const _params = {
        folder: params,
        c_utente: this.dataUserDetails.c_utente,
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/jwt',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    return response.data;
}

const tutorial = async function (params) {

    try {

        const scopes = ['tutorial', 'guide'];

        const results = await this.dataLayer({
            url: this.config.paths.apiURL + 'pim/entities',
            params: {
                'language': this.language,
                'origin': 'master',
                'entity': 'customerHub',
                'scope': scopes
            },
            cacheAge: null,
            userToken: this.userToken,
        });

        runInAction(() => {
            // find 'tutorial' key
            const tutorialData = results.data.find(item => item.data.tutorial?.edges);
            if (tutorialData) {
                this.dataPim.tutorial = tutorialData.data.tutorial.edges;
            }

            // find 'guide' key
            const guideData = results.data.find(item => item.data.guide?.edges);
            if (guideData) {
                let guide = guideData.data.guide.edges;

                if (this.dataUserDetails.logo === '002') {
                    guide = guide.filter(item => item.node.chiave !== 'garanzia');
                }
                if (!this.dataAutorizzazioni.reclami_web.abilitato) {
                    guide = guide.filter(item => item.node.chiave !== 'reclamo');
                }

                this.dataPim.guide = guide;
            }
        });


    } catch (error) {
        console.error(error);
        this.notificationAdd(error.message, error.id, 5000, "error");
    }
}

const getAssetsList = async function (params) {
    const _params = {
        c_utente: params.c_utente || null,
        path: params.path || null
    };

    if (params === '/protected/area download' || params === '/protected/listini') {
        _params.path = '/protected/area download|/protected/listini/' + this.dataUserDetails.c_area_listino;
    }

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/list',
        params: _params,
        cacheAge: 0,
        userToken: this.userToken
    });

    // runInAction(() => {
    //     if (response.data.results && response.data.results.countResults > 0) {
    //         console.log('>>> TODO save results to state var to be able to use into the dashboard card');
    //         console.log('>>> results ', response.data.results);
    //     }
    // });

    return response.data;
};

const getAsset = async function (params)
{
    try {
        return await this.dataLayer({
            url: this.config.paths.apiURL + 'pim/entities',
            params: {
                'language': this.language,
                'origin': 'master',
                'entity':  params.asset.toString(),
                'scope': 'protected',
                'options': {
                    'root': params.root
                }
            },
            cacheAge: 180000, // 3 minutes
            userToken: this.userToken,
        })
        .then(response => atob(response.data.data))
        .then(binaryString => {
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }
            return byteArray;
        })
        .then(byteArray => new Blob([byteArray], { type: 'application/pdf' }))
        .then(blob => URL.createObjectURL(blob))
        .catch(error => {
            console.log(error);
            this.notificationAdd('Blob conversion error: ' + error.message, error.id, 5000, "error");
        });

    } catch (error) {
        console.error(error);
        this.notificationAdd(error.message, error.id, 5000, "error");
    }
}

const getAssetVideo = async function (params) {
    const _params = {
        asset: params.asset, folder: params.root
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/asset/video', cacheAge: 0, params: _params, userToken: this.userToken
    });

    if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
            category: "academy", action: "asset", label: params.name
        });
    }

    return response.data.data.hasOwnProperty('mp4') ? response.data : null;
};

const getLastVersionUpdate = async function (params, options)
{
    try {

        const results = await this.dataLayer({
            url: this.config.paths.apiURL + 'pim/entities',
            params: {
                'language': this.language,
                'origin': 'brand',
                'entity': 'customerHub',
                'scope': 'versionUpdate'
            },
            cacheAge: null,
            userToken: this.userToken,
        });

        if (results.data &&
            results.data.getAggiornamentoSoftwareListing &&
            results.data.getAggiornamentoSoftwareListing.edges &&
            results.data.getAggiornamentoSoftwareListing.edges.length > 0) {
            return results.data.getAggiornamentoSoftwareListing.edges[0].node;
        }

    } catch (error) {
        console.error(error);
        this.notificationAdd(error.message, error.id, 5000, "error");
    }
}

export {
    tutorial,
    getJwt,
    getAssetsList,
    getAsset,
    getAssetVideo,
    getLastVersionUpdate
}
