import React from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'
import Route from 'route-parser';
//var ScrollArea = require('react-scrollbar');
import Version from "System/components/version.jsx";

export default inject(
  "rootstore",
  "langstore"
)(
  observer((props) => {
    const {rootstore , langstore} = props;
    let opens = toJS(rootstore.menusOpen);
    let menuGroups = ['main'];

    const [isNewsletterOpen, setIsNewsletterOpen] = useState(false)

    const allRoutes = toJS(rootstore.routes);
    const commleAllRoutes = [
        'commlelistaprodottimarketing',
        'commlelistaprodotticampionarioante',
        'commlelistacontratticampionari',
        'commlelistaordinioggettistica',
        'commlelistaprodottiomaggio',
        'commlelistaordinicompleta'
    ];

    const commleTeamExpoRoutes = [
        'commlelistaordinicompleta', // this is the only route visible for TeamExpo and must be the last due to row 23
    ];

    const commleDataViewerRoutes = [
        'commlelistaprodottimarketing',
        'commlelistaprodotticampionarioante',
        'commlelistacontratticampionari',
        'commlelistaprodottiomaggio',
        'commlelistaordinicompleta'
    ];

    const userRole = rootstore.config?.commerciale?.enabled === true ? rootstore.dataCommerciale.userRole : 'DISABLED';

    let commleRoutes = commleAllRoutes;
    switch (userRole) {
        case 'DISABLED':
            commleRoutes = [];
            break;
        case 'EXPO':
            commleRoutes = commleTeamExpoRoutes;
            break;
        case 'VIEWER':
            commleRoutes = commleDataViewerRoutes;
            break;
    }

    const menuCommle = allRoutes.filter(route => commleRoutes.includes(route.id));

    const [open, setOpen] = useState(false)

    function clickMenuItem(route, expandTree) {
      const { actionType } = route;

      switch (actionType) {
        case "none":
          if (expandTree !== false) {
            rootstore.routesTreeToggle(route.idMenu);
          }
          break;
        case "ssoredirect":
          rootstore.ssoRedirect(route.path, route.params);
          //rootstore.routeChangeWithLink(route.path,route.external,route.target,route.params);
          break;
        case "externalurl":
          window.open(route.path, "_blank");
          break;
        default:
          if (expandTree !== false) {
            rootstore.routesTreeOpen(route.idMenu);
          }
          rootstore.routeChangeWithLink(
            route.path,
            route.external,
            route.target,
            route.params
          );
      }
      if (
        rootstore.viewportCurrent === "mobile" ||
        rootstore.viewportCurrent === "tablet"
      ) {
        rootstore.uiSidebarToggle();
      }
    }

    const showUsersList = !!rootstore.userInfo.commercialPersonID || (rootstore.userInfo.internal && rootstore.userInfo.canImpersonificate);

    const privacyPolicyUrl = rootstore.config?.privacyPolicy?.[rootstore.language]

    return (
      <div className="sidebar_menu_list">

            {/* TODO COMMERCIALE define with the team how to manage the menu */}
            {(commleRoutes.length > 0 && (rootstore.userInfo.isExpoUser || rootstore.userInfo.isCommleDataViewer || rootstore.userInfo.commercialPersonID)) &&
                <nav className="ts_sidebar_menu ts_sidebar_menu_span">
                    <div className={`ts_sidebar_item has_children ${open ? 'open' : ''}`}>
                        <div
                            className="ts_sidebar_item_title d-flex align-items-center"
                            onClick={() => setOpen(!open) }
                            style={{color: "white"}}
                        >
                            <div className="mr-auto ">
                                <i className= "fa fa-archive"/>
                                <span className="ts_text2 ts_sidebar_item_label">
                                    {langstore.t('creazione_ordini_web','Creazione ordini')}
                                </span>
                            </div>
                            <span className="has_child_arrow fas fa-caret-right float-right"/>
                        </div>

                        {open && (
                            <div>
                                {
                                    menuCommle.map((menu, idx) => {
                                        return (
                                            <div key={idx} className="ts_sidebar_item_child">
                                                <div
                                                    className={`ts_sidebar_item_title`}
                                                    onClick={() => {
                                                        rootstore.routeChange(menu.path);
                                                        if (
                                                            rootstore.viewportCurrent === "mobile" ||
                                                            rootstore.viewportCurrent === "tablet"
                                                        ) {
                                                            rootstore.uiSidebarToggle();
                                                        }
                                                    }}
                                                >
                                                    <span className="style_sidebar_subitem">
                                                        <i className="fas fa-circle"/>
                                                        {langstore.t(menu.titleKey, menu.titleString)}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )}
                    </div>
                </nav>
            }

        {menuGroups.map((group, group_i) => {
          let groupItems = rootstore.routesTreeCurrent[group];

          return (
            <nav className="ts_sidebar_menu ts_sidebar_menu_span" key={group_i}>
              {groupItems.map((route, i) => {
                let isOpen = opens.indexOf(route.idMenu) >= 0;
                let path = new Route(route.path);
                let routeIsActive =
                  path.match(rootstore.route) !== false ||
                  (route.children &&
                    route.children.filter(
                      (r) => r.path === rootstore.route
                    ).length !== 0);
                const menuItemIsOpenClass = isOpen ? "open" : "";
                const routeIsActiveCLass = routeIsActive ? "active" : "";
                const hasChildrenClass = route.children ? "has_children" : "";
                const hasParentClass = route.parent ? "has_parent" : "";

                return (



                  <div
                    className={`ts_sidebar_item ${routeIsActiveCLass} ${hasChildrenClass} ${hasParentClass} ${menuItemIsOpenClass}`}
                    key={route.idMenu}

>
                    <div
                      className={`ts_sidebar_item_title d-flex align-items-center`}
                      onClick={() => {
                        if (route.children) {
                          rootstore.routesTreeToggle(route.idMenu);
                        } else {
                          clickMenuItem(route);
                        }
                      }}
                    >
                      <div className="mr-auto">
                        <i className={route.iconClass ? route.iconClass : "fa fa-folder"}/>
                        <span
                            className="ts_text2 ts_sidebar_item_label">{langstore.t(route.titleKey, route.titleString)}
                        </span>
                      </div>

                      {route.children && (
                        <span className="has_child_arrow fas fa-caret-right float-right" />
                      )}

                      {route.counter &&
                        rootstore[route.counter] &&
                        rootstore[route.counter].length >= 1 && (
                          <span className="ts_sidebar_count">
                            {rootstore[route.counter].length}
                          </span>
                        )}
                    </div>

                    {route.children && (
                      <div
                        className={
                          isOpen === true
                            ? "ts_sidebar_item_child open"
                            : "ts_sidebar_item_child"
                        }
                      >
                        {route.children.map((routeChild, ii) => {
                          let path = new Route(routeChild.path);
                          let routeChildIsActive =
                            path.match(rootstore.route) !== false;

                          return (
                            <div
                              key={routeChild.idMenu}
                              className={
                                routeChildIsActive
                                  ? "ts_sidebar_item_title active"
                                  : "ts_sidebar_item_title"
                              }
                              onClick={() => clickMenuItem(routeChild, false)}
                            >
                              <span className="style_sidebar_subitem">
                                <i className="fas fa-circle" />
                                {langstore.t(
                                  routeChild.titleKey,
                                  routeChild.titleString
                                )}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </nav>
          );
        })}

        {rootstore.userToken ? (
            <nav className="ts_sidebar_menu">
                {rootstore.userInfo.internal === false &&
                    rootstore.viewportCurrent === "mobile" && (
                        <div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={"ts_sidebar_item_title"}
                                    onClick={() => {
                                        rootstore.routeChange("/comunicazioni");
                                        if (
                                            rootstore.viewportCurrent === "mobile" ||
                                            rootstore.viewportCurrent === "tablet"
                                        ) {
                                            rootstore.uiSidebarToggle();
                                        }
                                    }}
                                >
                                    <i className="fa fa-bell"/>
                                    <span className="ts_text2 ts_sidebar_item_label">
                        {langstore.t("communications")}
                      </span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={"ts_sidebar_item_title"}
                                    onClick={() => {
                                        rootstore.routeChange("/acl");
                                        if (
                                            rootstore.viewportCurrent === "mobile" ||
                                            rootstore.viewportCurrent === "tablet"
                                        ) {
                                            rootstore.uiSidebarToggle();
                                        }
                                    }}
                                >
                                    <i className="fa fa-users"/>
                                    <span className="ts_text2 ts_sidebar_item_label">
                        {langstore.t("user_management")}
                      </span>
                                </div>
                            </div>
                        </div>
                    )}

                {showUsersList &&
                    <div className="ts_sidebar_item">
                        <div className="ts_sidebar_item_title d-flex align-items-center">
                            <div className="mr-auto">
                                <i className="fas fa-chart-line"></i>
                                <span
                                    className="ts_text2 ts_sidebar_item_label"
                                    onClick={() => {
                                        rootstore.routeChange("/lista-utenti");
                                    }}
                                >Lista utenti</span>
                            </div>
                        </div>
                    </div>
                }

                {rootstore.userInfo.canImpersonificate && (
                    <div className="ts_sidebar_item">
                        <div
                            className="ts_sidebar_item_title"
                            onClick={() => {
                                rootstore.routeChange("/");
                                if (
                                    rootstore.viewportCurrent === "mobile" ||
                                    rootstore.viewportCurrent === "tablet"
                                ) {
                                    rootstore.uiSidebarToggle();
                                }
                            }}
                        >
                            <i className="fas fa-user-circle"/>
                            <span className="ts_text2 ts_sidebar_item_label">
                    {langstore.t("impersonates", "Impersonifica")}
                  </span>
                        </div>
                    </div>
                )}
                {/*{rootstore.userInfo.internal === true && rootstore.userInfo.salesperson &&
                        <div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={rootstore.routeIdCurrent === 'ordini-archivio' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            rootstore.routeChange("/ordini");
                                            if (rootstore.viewportCurrent === "mobile" || rootstore.viewportCurrent === "tablet") {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-cart-arrow-down"/>
                                    <span
                                        className="ts_text2 ts_sidebar_item_label">{langstore.t("ordini_archivio", "Archivio ordini")}</span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={rootstore.routeIdCurrent === 'ordini-archivio' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            rootstore.routeChange("/fatture");
                                            if (rootstore.viewportCurrent === "mobile" || rootstore.viewportCurrent === "tablet") {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-file-invoice"/>
                                    <span
                                        className="ts_text2 ts_sidebar_item_label">{langstore.t("ordini_archivio", "Archivio fatture")}</span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={rootstore.routeIdCurrent === 'sostituzioni' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            rootstore.routeChange("/sostituzioni");
                                            if (rootstore.viewportCurrent === "mobile" || rootstore.viewportCurrent === "tablet") {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-exchange-alt"/>
                                    <span
                                        className="ts_text2 ts_sidebar_item_label">{langstore.t("sostituzioni", "Sostituzioni")}</span>
                                </div>
                            </div>
                        </div>
                    }*/}

                {/* TODO COMMERCIALE define with the team how to manage the menu */}
                {/* TODO passare da codice hardcodato a items ad elemento nel DB */}

                {rootstore.userInfo.canSendAcademyNewsletter && (
                    <div className={`ts_sidebar_item has_children ${isNewsletterOpen ? 'open' : ''}`}>
                        <div
                            className="ts_sidebar_item_title d-flex align-items-center"
                            onClick={() => setIsNewsletterOpen(!isNewsletterOpen)}
                            style={{ color: "white" }}
                        >
                            <div className="mr-auto ">
                                <i className="fa fa-envelope"/>
                                <span className="ts_text2 ts_sidebar_item_label">
                      {langstore.t("newsletter", "Newsletter")}
                    </span>
                            </div>
                            <span className="has_child_arrow fas fa-caret-right float-right"/>
                        </div>

                        {isNewsletterOpen && (
                            <div>
                                <div className="ts_sidebar_item_child open">
                                    <div
                                        className={`ts_sidebar_item_title`}
                                        onClick={() => {
                                            rootstore.routeChange("/newsletter-video-academy/crea");
                                            if (
                                                rootstore.viewportCurrent === "mobile" ||
                                                rootstore.viewportCurrent === "tablet"
                                            ) {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }}
                                    >
                        <span className="style_sidebar_subitem">
                          <i className="fas fa-circle"/>
                            {langstore.t("create", "Crea")}
                        </span>
                                    </div>
                                    <div
                                        className={`ts_sidebar_item_title`}
                                        onClick={() => {
                                            rootstore.routeChange("/newsletter-video-academy/lista");
                                            if (
                                                rootstore.viewportCurrent === "mobile" ||
                                                rootstore.viewportCurrent === "tablet"
                                            ) {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }}
                                    >
                        <span className="style_sidebar_subitem">
                          <i className="fas fa-circle"/>
                            {langstore.t("list", "Lista")}
                        </span>
                                    </div>
                                    <div
                                        className={`ts_sidebar_item_title`}
                                        onClick={() => {
                                            rootstore.routeChange("/newsletter-video-academy/iscritti");
                                            if (
                                                rootstore.viewportCurrent === "mobile" ||
                                                rootstore.viewportCurrent === "tablet"
                                            ) {
                                                rootstore.uiSidebarToggle();
                                            }
                                        }}
                                    >
                        <span className="style_sidebar_subitem">
                          <i className="fas fa-circle"/>
                            {langstore.t("iscritti", "Iscritti")}
                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {privacyPolicyUrl &&
                    <div className="ts_sidebar_item">
                        <div
                            className="ts_sidebar_item_title"
                            onClick={() => window.open(privacyPolicyUrl, '_blank')}
                        >

                            <i className="fas fa-info"/>
                            <span
                                className="ts_text2 ts_sidebar_item_label">{langstore.t("privacy_policy", "Privacy policy")}</span>

                        </div>
                    </div>
                }

                <div className="ts_sidebar_item">
                    <div
                        className={
                            rootstore.routeIdCurrent === "userprofile"
                                ? "ts_sidebar_item_title active"
                                : "ts_sidebar_item_title"
                        }
                        onClick={() => {
                            rootstore.routeChange("/profile");
                            if (
                                rootstore.viewportCurrent === "mobile" ||
                                rootstore.viewportCurrent === "tablet"
                            ) {
                                rootstore.uiSidebarToggle();
                            }
                        }}
                    >
                        <i className="fas fa-user"/>
                        <span className="ts_text2 ts_sidebar_item_label">
                  {langstore.t("section_profile", "Profilo")}
                </span>
                    </div>
                </div>

                <div className="ts_sidebar_item">
                    <div
                        className={"ts_sidebar_item_title"}
                        onClick={() => {
                            rootstore.userLogout();
                            if (
                                rootstore.viewportCurrent === "mobile" ||
                                rootstore.viewportCurrent === "tablet"
                            ) {
                                rootstore.uiSidebarToggle();
                            }
                        }}
                    >
                        <i className="fa fa-sign-out-alt icon_m"/>
                        <span className="ts_text2 ts_sidebar_item_label">
                  {langstore.t("user_logout", "Esci")}
                </span>
                    </div>
                </div>

                {rootstore.userInfo.internal === false &&
                    rootstore.viewportCurrent === "mobile" && (
                        <div>
                            <div className="divider-horizontal my-3"/>
                            <div className="options-mobile pb-4">
                                <div className="ts_sidebar_item">
                                    {langstore.t("last_update", "ultimo aggiornamento")}
                                    : <span className="">{rootstore.last_update}</span>
                                </div>
                                <div className="ts_sidebar_item mt2">
                                    {langstore.t(
                                        "next_update",
                                        "prossimo aggiornamento"
                                    )}
                                    : <span className="">{rootstore.next_update}</span>
                                </div>
                                <div className="ts_sidebar_item mt2">
                                    <Version/>
                                </div>
                            </div>
                        </div>
                    )}
            </nav>
        ) : (
            <nav className="ts_sidebar_menu">
                <div className="ts_sidebar_item">
                    <div
                        className={
                            rootstore.routeIdCurrent == "userlogin"
                                ? "ts_sidebar_item_title active"
                                : "ts_sidebar_item_title"
                        }
                        onClick={() => rootstore.routeChange("/login")}
                    >
                        <i className="far fa-user icon_m"/>
                        <span className="ts_text2 ts_sidebar_item_label">
                  {langstore.t("user_login", "Accedi al portale")}
                </span>
                    </div>
                </div>
            </nav>
        )}
      </div>
    );
  })
);
