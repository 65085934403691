import { toJS, runInAction } from "mobx";
import { faker } from '@faker-js/faker';
import langstore from "System/i18n/translator";
import { validateExtensionAttachments } from "System/utilities/files";
import { attachmentUpload, attachmentRemove } from 'Application/utils/attachmentsManager';
import * as _ from "lodash";

// TODO COMMERCIALE missing translations

/************************************************************************************************
 *
 *  ERROR CODES
 *  . ERR101: getClientsList
 *  . ERR102: getArticleList
 *  . ERR103: getContractList
 *  . ERR104: getCartGuidCommle
 *  . ERR105: getCartParamsCommle
 *  . ERR106: getDestinazioneByCNome
 *  . ERR107: getOrdersList
 *  . ERR108: getExpoOrdersListFromCart
 *  . ERR109: getAllCommercialeCarts
 *  . ERR110: getDataForDsign
 *  . ERR111: getModelsByAnte
 *
 *  . ERR201: setClient
 *  . ERR202: setContracts
 *  . ERR203: setCartDetails
 *  . ERR204: setCartDetailsExtraData
 *  . ERR205: toggleCommleOrderList
 *
 *  . ERR301: commleMarketingInitProductsList
 *  . ERR302: commleInitCart
 *  . ERR303: commleCampionariInitContractsList
 *  . ERR304: commleCampionariInitProductsList
 *  . ERR305: commleOggettisticaInitOrdersList
 *  . ERR306: commleInitStoredCart
 *  . ERR307: commleCampionarioAnteInitProductsList
 *  . ERR308: commleOmaggiInitProductsList
 *
 *  . ERR401: clientListModalOpen
 *
 *  . ERR501: addToCartCommle
 *  . ERR502: massiveAddToCartCommle
 *  . ERR503: deleteRowFromCartCommle
 *  . ERR504: permanentlyDeleteCartCommle
 *  . ERR505: confirmCartCommle
 *  . ERR506: addToCartOrdersCommle
 *  . ERR507: confirmPartialCartCommle
 *
 *  . ERR601: goToPage
 *
 * */


/************************************************************************************************
*
*  Get func
*
* */

// TODO COMMERCIALE only for dev purposes
const fakeProductGenerator = function () {
  const products = [];
  for (let i = 0; i < 64; i++) {
    const product = {
      name: faker.commerce.productName(),
      description: faker.commerce.productDescription(),
      image: faker.image.urlLoremFlickr({ category: 'kitchens', width: 500, height: 500 }),
      price: faker.commerce.price({ min: 10, max: 999, dec: 2 })
    };
    products.push(product);
  }
  // console.log('>>> products ', products);
  products.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

  return products;
}


const getClientsList = async function (type, c_nome = 0) {
  try {

    const clientList = await this.dataLayer({
      url: this.config.paths.apiURL + 'profilo/lista-clienti',
      params: {
        singleCCliente: c_nome
      },
      cacheAge: null,
      userToken: this.userToken,
    });

    if (clientList.data?.clients?.length === 0) {
      throw {
        id: 'missingClient',
        message: langstore.t('client_data_missing','Dati cliente mancanti, impossibile proseguire.')
      };
    }

    // single client (c_nome) asked need to save on state data
    if (c_nome) {
      runInAction(() => {
        this.dataCommerciale[type].client = clientList.data.clients[0] || 'MISSING';
      });
    }

    return clientList;

  } catch (error) {
    this.notificationAdd('ERR101: ' + error.message, error.id, 10000, "error");
  }
}




const getArticleList = async function (scope, extraData = {}) {
  try {

    const type = this.scopeTypeConverter(scope);

    const client = this.dataCommerciale[type].client.c_nome;

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/articoli/lista',
      params: {
        'scope': scope,
        'cliente': client,
        'userLang': this.language,
        ...extraData
      },
      cacheAge: null,
      userToken: this.userToken,
    });

    runInAction(() => {
      this.dataCommerciale[type].products = results.data.products;
      this.dataCommerciale[type].pagination = results.data.pagination;
    });

    return results.data.products;

  } catch (error) {
    this.notificationAdd('ERR102: ' + error.message, error.id, 5000, "error");
  }
}



const getContractList = async function (client) {
  try {

    if (!client) {
      throw {
        id: 'missingClient',
        message: langstore.t('client_data_missing','Dati cliente mancanti, impossibile proseguire.')
      };
    }

    // if contract list is already available and client is not changed, no need to call API
    if ( typeof this.dataCommerciale.campionari.contracts.list !== 'undefined'
      && !_.isEmpty(this.dataCommerciale.campionari.contracts.list)
      && ( typeof client.c_nome === 'undefined' || client.c_nome === this.dataCommerciale.campionari.client?.c_nome )) return;


    const contractsList = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/contratti/lista',
      params: {
        'cliente': client.c_nome,
        'c_lingua': client.c_lingua,
        'ita_estero': client.ita_estero,
      },
      cacheAge: null,
      userToken: this.userToken,
    });

    runInAction(() => {
      this.dataCommerciale.campionari.contracts.list = contractsList.data;
      if (client.c_nome !== this.dataCommerciale.campionari.client.c_nome) {
        this.dataCommerciale.campionari.contracts.selected = null;
      }
    });

    return contractsList;

  } catch (error) {
    this.notificationAdd('ERR103: ' + error.message, error.id, 5000, "error");
  }
}


const getOrdersList = async function (client) {
  try {

    if (!client) {
      throw {
        id: 'missingClient',
        message: langstore.t('client_data_missing','Dati cliente mancanti, impossibile proseguire.')
      };
    }

    const allOrders = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/oggettistica/lista-ordini',
      params: {
        'c_cliente': client
      },
      cacheAge: null,
      userToken: this.userToken,
    });

    const selectedOrders = [];
    if (allOrders.data.length > 0) {
      allOrders.data.map(ord => {
        if (ord.ordcli_ogg) selectedOrders.push(ord.ordcli);
      })
    }

    runInAction(() => {
      this.dataCommerciale.oggettistica.orders.list = allOrders.data;
      this.dataCommerciale.oggettistica.orders.selected = selectedOrders;
    });

    return {
      list: allOrders,
      selected: selectedOrders
    };

  } catch (error) {
    this.notificationAdd('ERR107: ' + error.message, error.id, 5000, "error");
  }
}


const getAllCommercialeCarts = async function (client = 0) {
  try {

    const role = toJS(this.dataCommerciale.userRole) || this.setUserRole();

    const selectedOrders = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/lista-ordini',
      params: {
        'c_cliente': client,
        'commercial_id': parseInt(this.userInfo.commercialPersonID) || 0,
        'user_role': role,
        'c_stato_wf': role === 'EXPO' ? 'ST_'+role : ''
      },
      cacheAge: -1,
      userToken: this.userToken,
    });

    runInAction(() => {
      this.dataCommerciale.all.orders.list = selectedOrders.data;
    });
  } catch (error) {
    this.notificationAdd('ERR109: ' + error.message, error.id, 5000, "error");
  }
}


const getExpoOrdersListFromCart = async function (guid) {
  try {

    const selectedOrders = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/lista-ordini-expo',
      params: {
        'guid': guid
      },
      cacheAge: -1,
      userToken: this.userToken,
    });

    runInAction(() => {
      this.dataCommerciale.oggettistica.orders.selected = selectedOrders.data.flatMap(o => {
        return {
          key: o.key_ordine_gemo,
          status: o.c_stato_avanzamento,
          c_pratica: o.c_pratica,
          c_pratica_guid: o.c_pratica_guid
        }
      });
    });

    return selectedOrders;

  } catch (error) {
    this.notificationAdd('ERR108: ' + error.message, error.id, 5000, "error");
  }
}

const getCartGuidCommle = async function (scope, client, skipCartCreation = false) {
  try {

    // get cart GUID
    const cart = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/guid',
      cacheAge: null,
      params: {
        numeratore: scope,
        cliente: client,
        skipCartCreation: skipCartCreation
      },
      userToken: this.userToken,
    });

    if (cart && cart.data) {
      return cart.data;
    }

  } catch (error) {
    this.notificationAdd('ERR104: ' + error.message, error.id, 5000, "error");
  }
}


const getCartParamsCommle = async function (scope, c_lingua, fg_capo_area_estero) {
  try {

    const type = this.scopeTypeConverter(scope);
    const linguaIso = typeof c_lingua !== 'undefined' ? this.langCodeConverter(c_lingua) : this.config.languages.languageDefault;

    // get cart params for discount codes or similar
    const params = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/parametri',
      cacheAge: null,
      params: {
        'numeratore': scope,
        'lingua_iso': linguaIso,
        'fg_capo_area_estero': fg_capo_area_estero
      },
      userToken: this.userToken,
    });

    if (params && params.data) {
      runInAction(() => {
        this.dataCommerciale[type].params = params.data;
      });
      return params.data;
    }

  } catch (error) {
    this.notificationAdd('ERR105: ' + error.message, error.id, 5000, "error");
  }
}


const getDestinazioneByCNome = async function (scope, c_cliente) {
  try {

    if (typeof c_cliente === 'undefined') {
      throw {
        id: 'missingClient',
        message: langstore.t('','Mancano i dati del cliente. Impossibile proseguire.')
      };
    }

    const type = this.scopeTypeConverter(scope);

    const destinations = await this.dataLayer({
      url: this.config.paths.apiURL + 'ecomm/carrello/destinazioni-merce',
      cacheAge: null,
      params: {
        c_cliente: c_cliente
      },
      userToken: this.userToken,
    });

    if (destinations && destinations.data && destinations.data.length) {

      runInAction(() => {
        this.dataCommerciale[type].params.destinations = destinations.data;
      });

      return destinations.data;

    }

    return false;

  } catch (error) {
    this.notificationAdd('ERR106: ' + error.message, error.id, 5000, "error");
  }
}


const getDataForDsign = async function(params) {
  try {

    let result = {};

    if (params.type !== 'campionari') {
      return result;
    }

    const campionari = toJS(this.dataCommerciale.campionari);

    if (campionari.client.e_mail_aziendale === '' || campionari.contracts.selected === '') {
      this.modalClose();
      this.loading = false;

      if (campionari.client.e_mail_aziendale === '') {
        throw {
          id: 'missingClientsEmail',
          message: langstore.t('','Manca l\'email aziendale del cliente. Impossibile proseguire')
        }
      }

      if (campionari.contracts.selected === '') {
        throw {
          id: 'missingSelectedContracts',
          message: langstore.t('','Manca un contratto selezionato. Impossibile proseguire')
        }
      }
    }

    result.cliente = campionari.client;

    const selectedContract = campionari.contracts.selected;

    if (selectedContract === 'custom') {
      result.template = selectedContract;
    } else {
      result.template = campionari.contracts.list.filter(contract => contract.id.toString() === selectedContract)
                                                  .map(contract => contract.template_contratto)[0];
    }

    return result;

  } catch (error) {
    this.notificationAdd('ERR110: ' + error.message, error.id, 10000, "error");
  }
}



const getModelsByAnte = async function() {
  try {

    const results = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/ante/modelli',
      cacheAge: null,
      params: null,
      userToken: this.userToken,
    });

    if (results && results.data && results.data.length) {
      runInAction(() => {
        this.dataCommerciale.models = results.data;
      });
    }

  } catch (error) {
    this.notificationAdd('ERR111: ' + error.message, error.id, 10000, "error");
  }
}


/************************************************************************************************
 *
 *  Genercic "set" func
 *
 * */

const setClient = async function (scope, client) {
  try {

    const type = this.scopeTypeConverter(scope);
    const cart = await this.getCartGuidCommle(scope, client.c_nome, true);

    // more data are needed depends on requested type
    switch (type) {
      case 'campionari':
        await this.getContractList(client);
        break;
      case 'oggettistica':
        await this.getOrdersList(client.c_nome);
        break;
      case 'all':
        // get all carts by client.c_name
        await this.getAllCommercialeCarts(client.c_nome);
        break;
    }

    const cartDetails = await this.setCartDetails(type, cart.c_guid);

    runInAction(() => {
      this.dataCommerciale[type].client = client;
      this.dataCommerciale[type].cart = cartDetails;
    });

    await this.getArticleList(scope);

    const modal = toJS(this.modalItems);
    if (modal.length) {
      this.modalClose();
    }

  } catch (error) {
    this.notificationAdd('ERR201: ' + error.message, error.id, 5000, "error");
  }
}


const setContracts = async function (contract) {
  try {

    let contractId = 'custom';
    if (typeof contract === "object") {
      contractId = contract.id;
    }

    runInAction(() => {
      this.dataCommerciale.campionari.contracts.selected = contractId;
    });

    this.goToPage('campionari', 'list', contractId);

  } catch (error) {
    this.notificationAdd('ERR202: ' + error.message, error.id, 5000, "error");
  }
}


const resetFiltersState = function () {
  const initialState = {
    pageNumber: 0,
    filterBy: '',
    orderBy: 'nome',
    orderByDirection: 'asc'
  }

  runInAction(() => {
    this.dataCommerciale.filters = initialState;
  });
}


const setFilteredArticle = async function (scope, filters) {
  const previousFilters = toJS(this.dataCommerciale.filters);
  const newFilters = {...previousFilters, ...filters};
  runInAction(() => {
    this.dataCommerciale.filters = newFilters;
  });
  await this.getArticleList(scope, newFilters);
}


const setCartDetails = async function (type, guid) {
  try {

    this.setCartLoading(true);

    let cart = {};

    if (guid !== '') {
      // get cart details by guid
      cart = await this.dataLayer({
        url: this.config.paths.apiURL + 'commle/carrello/vedi',
        cacheAge: -1, // need to be sure that the cache is never used
        params: {
          guid: guid,
        },
        userToken: this.userToken,
      });
    }

    // prepare empty cart data
    let cartResult = {
      count: 0,
      testa: {},
      righe: {}
    };

    if (cart.status === 'OK' && cart.data) {

      // retrieve client data if not exist on state or if requested by team expo (need to be always up to dated)
      if ( _.isEmpty(this.dataCommerciale[type].client && this.dataCommerciale.userRole === 'EXPO')) {
        await this.getClientsList(type, cart.data.testa.c_cliente);
      }

      switch (type) {
        case 'campionari':
          // retrieve contracts data if not exist on state
          await this.getContractList(this.dataCommerciale.campionari.client);
          break;
        case 'oggettistica':
          // need to know order list and selected orders to show cart correctly
          await this.getOrdersList(cart.data.testa.c_cliente);
          await this.getExpoOrdersListFromCart(cart.data.testa.c_guid);
          break;
      }

      // calculate cart total qta
      let countQta = 0;
      cart.data.righe.forEach(p => {
        if (p.fg_riga_annullato) return countQta;
        countQta += p.n_qta;
      });

      if (type === 'oggettistica') {
        const isProductListPage = this.route.split('/').includes('lista-prodotti');
        if (isProductListPage && this.dataCommerciale.userRole === 'EXPO') {
          const selectedNOrdineGM = parseInt(this.dataCommerciale[type].orders.active.split('-').pop());
          countQta = 0;
          cart.data.righe.forEach(p => {
            if (p.fg_riga_annullato || selectedNOrdineGM !== p.n_ordine_gm) return countQta;
            countQta += p.n_qta;
          });
        } else {
          countQta = this.dataCommerciale[type].orders.selected.length;
        }
      }

      cartResult = {
        'count': countQta,
        'testa': cart.data.testa,
        'righe': cart.data.righe
      }

      const extraData = JSON.parse(cart.data.testa.extra_data) || {};

      // set cart data on state manager
      runInAction(() => {
        this.dataCommerciale[type].cart = cartResult;
        this.dataCommerciale.cartLoading = false;
        if ( Object.keys(extraData).includes('contractId') ) { // this means that a contractId is set on this cart so we can assume that is a CMP order
          this.dataCommerciale.campionari.contracts.selected = extraData.contractId;
        }
      });

    }

    return cartResult;

  } catch (error) {
    this.notificationAdd('ERR203: ' + error.message, error.id, 5000, "error");
  }
}


const setCartExtraData = async function (type, guid, extraData) {
  try {

    const cart = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/modifica-extra-data',
      cacheAge: null,
      params: {
        guid: guid,
        extra_data: JSON.stringify(extraData)
      },
      userToken: this.userToken,
    });

    if (cart.status === 'OK' && cart.data) {
      // set cart data on state manager
      runInAction(() => {
        this.dataCommerciale[type].cart.testa.extra_data = extraData;
      });
      return cart;
    }
    return false;

  } catch (error) {
    this.notificationAdd('ERR204: ' + error.message, error.id, 5000, "error");
  }
}

const toggleCommleOrderList = function (checked, data) {
  try {

    let ordersToBeProcessed = toJS(this.dataCommerciale.oggettistica.orders.selected);
    const ordCli = data.ordcli;

    if (checked) {
      ordersToBeProcessed.push({
        key: ordCli,
        status: 'INIZIO'
      });
    } else {
      const orderToBeRemoved = ordersToBeProcessed.find(item => item.key === ordCli);
      ordersToBeProcessed = ordersToBeProcessed.filter(item => item.key !== orderToBeRemoved.key);
    }

    runInAction(() => {
      this.dataCommerciale.oggettistica.orders.selected = ordersToBeProcessed;
    });

  } catch (error) {
    this.notificationAdd('ERR205: ' + error.message, error.id, 5000, "error");
  }
}


const setActiveSubOrder = function (order) {
  runInAction(() => {
    this.dataCommerciale.oggettistica.orders.active = order;
  });
}


  /************************************************************************************************
 *
 *  Init func
 *
 * */

const commleMarketingInitProductsList = async function () {
  try {

    if (!this.userInfo.commercialPersonID && !this.userInfo.isExpoUser && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleMarketingInitProductsList";
    this.loadingAdd(loadingKey);

    const scope = 'MKT';

    this.resetFiltersState();
    this.setCartLoading(true);

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = {
    //   "c_nome": 9802,
    //     "ragione_sociale": "ACCOUNT PER TEST DA CRM ",
    //     "indirizzo": "VIA MOGLIANESE 23",
    //     "citta_sede_legale": "SCORZÈ",
    //     "e_mail_aziendale": "webmaster@arredo3.it",
    //     "c_lingua": "001",
    //     "c_marchio": "ARREDO3",
    //     "ita_estero": "ITALIA"
    // };
    // const client = {
    //   "c_nome": 2284,
    //   "ragione_sociale": "GRUPPO RIMA SPA - SAN GIOVANNI LUPATOTO ",
    //   "indirizzo": "VIA FABIO FILZI, 10",
    //   "citta_sede_legale": "MILANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // const client = { // per agente
    //   "c_nome": 26,
    //   "ragione_sociale": "NORD SALOTTO S.R.L. ",
    //   "indirizzo": "VIA ROMA, 132",
    //   "citta_sede_legale": "ALBIANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // const client = {
    //   "c_nome": 1348,
    //   "ragione_sociale": "ABC ARREDAMENTI SRL ",
    //   "indirizzo": "VIA DEL BRENNERO 31",
    //   "citta_sede_legale": "TRENTO",
    //   "e_mail_aziendale": "info@abcarredamenti.it",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR301: ' + error.message, error.id, 5000, "error");
  }
}


const commleInitCart = async function () {
  try {

    const loadingKey = "commleMarketingInitCart";
    this.loadingAdd(loadingKey);

    // get type from url
    const type = this.route.split('/').at(2).split('-').pop();

    const scope = this.scopeTypeConverter(type);
    const guid = this.routeParamsCurrent.guid;

    this.setCartLoading(true);

    // need to check always the cart details
    await this.setCartDetails(type, guid);

    // get cart extra params as discount code or "causale d'ordine"
    await this.getCartParamsCommle(scope, this.dataCommerciale[type].client.c_lingua, this.dataCommerciale[type].client.fg_capo_area_estero);

    // get possible destinations list
    await this.getDestinazioneByCNome(scope, this.dataCommerciale[type].client.c_nome)

    // need to have product details to open product modal details
    await this.getArticleList(scope);

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR302: ' + error.message, error.id, 5000, "error");
  }
}


const commleCampionarioAnteInitProductsList = async function () {
  try {
    if (!this.userInfo.commercialPersonID && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleCampionarioAnteInitProductsList";
    this.loadingAdd(loadingKey);

    const scope = 'CAL';

    this.resetFiltersState();
    this.setCartLoading(true);

    await this.getModelsByAnte();

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = {
    //   "c_nome": 9802,
    //     "ragione_sociale": "ACCOUNT PER TEST DA CRM ",
    //     "indirizzo": "VIA MOGLIANESE 23",
    //     "citta_sede_legale": "SCORZÈ",
    //     "e_mail_aziendale": "webmaster@arredo3.it",
    //     "c_lingua": "001",
    //     "c_marchio": "ARREDO3",
    //     "ita_estero": "ITALIA"
    // };
    // const client = {
    //   "c_nome": 2284,
    //   "ragione_sociale": "GRUPPO RIMA SPA - SAN GIOVANNI LUPATOTO ",
    //   "indirizzo": "VIA FABIO FILZI, 10",
    //   "citta_sede_legale": "MILANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // const client = { // per agente
    //   "c_nome": 26,
    //   "ragione_sociale": "NORD SALOTTO S.R.L. ",
    //   "indirizzo": "VIA ROMA, 132",
    //   "citta_sede_legale": "ALBIANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // const client = {
    //   "c_nome": 1348,
    //   "ragione_sociale": "ABC ARREDAMENTI SRL ",
    //   "indirizzo": "VIA DEL BRENNERO 31",
    //   "citta_sede_legale": "TRENTO",
    //   "e_mail_aziendale": "info@abcarredamenti.it",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR307: ' + error.message, error.id, 5000, "error");
  }
}

const commleCampionariInitContractsList = async function () {
  try {

    if (!this.userInfo.commercialPersonID && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleCampionariInitContractsList";
    this.loadingAdd(loadingKey);

    const scope = 'CMP';

    this.resetFiltersState();
    this.setCartLoading(true);

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = { // per agente
    //   "c_nome": 5,
    //   "ragione_sociale": "ARREDAMENTI BRUSCHI S.R.L. ",
    //   "indirizzo": "S.S. ROMEA, 50",
    //   "citta_sede_legale": "TAGLIO DI PO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    // always reset preselected contract
    runInAction(() => {
      this.dataCommerciale.campionari.contracts.selected = null;
    });

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR303: ' + error.message, error.id, 5000, "error");
  }
}


const commleCampionariInitProductsList = async function () {
  try {

    if (!this.userInfo.commercialPersonID && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleCampionariInitProductsList";
    this.loadingAdd(loadingKey);

    // must have a selected contract to show product list
    const contractId = this.route.split('/').at(-1);
    if (contractId === '') {
      const route = this.routes.find(route => route.id==='commlelistacontratticampionari')
      this.routeChange(route.path);
      return;
    }

    runInAction(() => {
      this.dataCommerciale.campionari.contracts.selected = contractId;
    });

    const scope = 'CMP';

    this.resetFiltersState();
    this.setCartLoading(true);

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = { // per agente
    //   "c_nome": 26,
    //   "ragione_sociale": "NORD SALOTTO S.R.L. ",
    //   "indirizzo": "VIA ROMA, 132",
    //   "citta_sede_legale": "ALBIANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR304: ' + error.message, error.id, 5000, "error");
  }
}


const commleOggettisticaInitOrdersList = async function () {
  try {

    if (!this.userInfo.commercialPersonID) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleOggettisticaInitOrdersList";
    this.loadingAdd(loadingKey);

    const scope = 'OGG';

    this.resetFiltersState();
    this.setCartLoading(true);

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = { // per agente
    //   "c_nome": 26,
    //   "ragione_sociale": "NORD SALOTTO S.R.L. ",
    //   "indirizzo": "VIA ROMA, 132",
    //   "citta_sede_legale": "ALBIANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR305: ' + error.message, error.id, 5000, "error");
  }

}



const commleOmaggiInitProductsList = async function () {
  try {

    if (!this.userInfo.commercialPersonID && !this.userInfo.isExpoUser && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleOmaggiInitProductsList";
    this.loadingAdd(loadingKey);

    const scope = 'OMG';

    this.resetFiltersState();
    this.setCartLoading(true);

    // TODO COMMERCIALE comment to prevent modal. only for dev purposes
    await this.clientListModalOpen(scope);

    // TODO COMMERCIALE remove. only for dev purposes
    // const client = {
    //   "c_nome": 9802,
    //     "ragione_sociale": "ACCOUNT PER TEST DA CRM ",
    //     "indirizzo": "VIA MOGLIANESE 23",
    //     "citta_sede_legale": "SCORZÈ",
    //     "e_mail_aziendale": "webmaster@arredo3.it",
    //     "c_lingua": "001",
    //     "c_marchio": "ARREDO3",
    //     "ita_estero": "ITALIA"
    // };
    // const client = {
    //   "c_nome": 2284,
    //   "ragione_sociale": "GRUPPO RIMA SPA - SAN GIOVANNI LUPATOTO ",
    //   "indirizzo": "VIA FABIO FILZI, 10",
    //   "citta_sede_legale": "MILANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // const client = { // per agente
    //   "c_nome": 26,
    //   "ragione_sociale": "NORD SALOTTO S.R.L. ",
    //   "indirizzo": "VIA ROMA, 132",
    //   "citta_sede_legale": "ALBIANO",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3"
    // };
    // const client = {
    //   "c_nome": 1348,
    //   "ragione_sociale": "ABC ARREDAMENTI SRL ",
    //   "indirizzo": "VIA DEL BRENNERO 31",
    //   "citta_sede_legale": "TRENTO",
    //   "e_mail_aziendale": "info@abcarredamenti.it",
    //   "c_lingua": "001",
    //   "c_marchio": "ARREDO3",
    //   "ita_estero": "ITALIA"
    // };
    // await this.setClient(scope, client);
    // TODO COMMERCIALE end

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR308: ' + error.message, error.id, 5000, "error");
  }
}


const commleInitStoredCart = async function () {
  try {

    if (!this.userInfo.commercialPersonID && !this.userInfo.isExpoUser && !this.userInfo.isCommleDataViewer) {
      throw {
        id: 'userNotAuthorized',
        message: langstore.t('','Utente non autorizzato')
      }
    }

    const loadingKey = "commleInitStoredCart";
    this.loadingAdd(loadingKey);

    this.setCartLoading(true);

    await this.getAllCommercialeCarts();

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR306: ' + error.message, error.id, 5000, "error");
  }

}

/************************************************************************************************
 *
 *  Modal func
 *
 * */

const openCommercialeModal = function (modalName, params, disableCloseOnBackClick = false) {
  runInAction(() => {
    this.modalOpen(modalName, params, disableCloseOnBackClick);
  });
}


const clientListModalOpen = async function (scope, forcedOpening = false) {
  try {
    const loadingKey = "clientListModalOpen";
    this.loadingAdd(loadingKey);

    const type = this.scopeTypeConverter(scope);

    // Get client list by user type (sub agent, agent, area manager)
    const clientsList = await this.getClientsList(type);

    if (!clientsList.data.clients && !clientsList.data.clients.length) {
      throw {
        id: 'missingClientsList',
        message: langstore.t('empty_client_list','La lista dei clienti è vuota. Impossibile proseguire.')
      };
    }

    const modal = toJS(this.modalItems);
    const client = toJS(this.dataCommerciale[type].client);

    // open modal to choose client if not already selected
    if (!modal.length && _.isEmpty(client) || forcedOpening) {
      const params = {
        scope: scope,
        clients: clientsList.data.clients
      };
      this.openCommercialeModal('ModalClientByUserSelect', params, true);
    } else {
      await this.getArticleList(scope);
    }

    runInAction(() => {
      this.loading = false;
    });

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR401: ' + error.message, error.id, 5000, "error");
  }

}




/************************************************************************************************
 *
 *  Action on cart func
 *
 * */

const setCartStatus = function (cart) {

  if (!cart) return false;

  return {
    guid: cart.testa.c_guid,
    anno: cart.testa.n_anno,
    numeratore: cart.testa.c_numeratore,
    ordine: cart.testa.n_ordine,
    editable: true,
    parziale: this.route.split('/').at(-2) === 'parziale',
    modifyProducts: true,
    showDetails: true,
    showOrdersList: false,  // used only in OGGETTISTICA scope
    annullato: cart.testa.fg_ordine_annullato,
    confermato: cart.testa.fg_ordine_confermato,
    completato: cart.testa.fg_ordine_completato,
    workFlowStatus: typeof cart.testa.c_stato_wf !== 'undefined' ? cart.testa.c_stato_wf.split('_')[1] || cart.testa.c_stato_wf : '',
    gemoOrderId: cart.testa.n_ordine_completato ? cart.testa.n_anno_completato + '-' + cart.testa.c_numeratore_completato + '-' + cart.testa.n_ordine_completato : '',
    extraData: cart.testa.extra_data ? JSON.parse(cart.testa.extra_data) : {},
    backToLabel: langstore.t('ecomm_torna_alla_lista','Torna alla lista')
  }

}


const addToCartCommle = async function (type, c_articolo, c_scheda, qta, extraData) {
  try {

    const scope = this.scopeTypeConverter(type);
    const client = this.dataCommerciale[type].client.c_nome

    let guid = this.dataCommerciale[type].cart.testa.c_guid;

    // if undefined guid means that cart not exist, so I need to create it
    if (typeof guid === 'undefined') {
      const guidWrapper = await this.getCartGuidCommle(scope, client);
      guid = guidWrapper.c_guid;
      await this.setCartDetails(type, guid); // need to restore cart data
    }

    // if not empty in certain scopes need to set extra data. for example contract id for "campionari" orders
    if (!_.isEmpty(extraData)) {
      await this.setCartExtraData(type, guid, extraData);
    }

    const parziale = extraData?.parziale || '';

    const _params = {
      'guid_ordine': guid,
      'parziale': parziale,
      'articolo': c_articolo,
      'scheda': c_scheda,
      'qta': qta
    };

    // add product to cart
    const cart = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/modifica-quantita',
      cacheAge: -1, // need to be sure that the cache is never used
      params: _params,
      userToken: this.userToken,
    });

    if (cart.status === 'OK' && cart.data) {
      runInAction(() => {
        this.dataCommerciale[type].cart.count += qta;
      });
    }

  } catch (error) {
    this.notificationAdd('ERR501: ' + error.message, error.id, 5000, "error");
  }
}


const massiveAddToCartCommle = async function (scope, client, contractId) {
  try {

    const type = this.scopeTypeConverter(scope);

    let guid = this.dataCommerciale[type].cart.testa.c_guid;

    // if undefined guid means that cart not exist, so I need to create it
    if (typeof guid === 'undefined') {
      const guidWrapper = await this.getCartGuidCommle(scope, client.c_nome);
      guid = guidWrapper.c_guid;
      await this.setCartDetails(type, guid); // need to restore cart data
    }

    // if not empty in certain scopes need to set extra data. for example contract id for "campionari" orders
    if (!this.dataCommerciale[type].cart.testa.extra_data) {
      await this.setCartExtraData(type, guid, {contractId: contractId});
    }

    // prepare params for massive add-to-cart
    const _params = {
      guid: guid,
      contractId: contractId
    };

    // call API to massive add to cart
    const added = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/aggiunta-massiva',
      cacheAge: -1, // need to be sure that the cache is never used
      params: _params,
      userToken: this.userToken,
    });


    let resultCodes = [];

    if (added.status === 'OK' && added.data.length > 0) {
      const flatData = added.data.flatMap(ar => ar.map(obj => obj.id_messaggio));
      resultCodes = flatData.filter((value, index, self) => self.indexOf(value) === index);
    }

    if ( resultCodes.length === 1 && resultCodes.pop() === 'M01' ) {

      // reload from server cart details before redirect user to cart
      await this.setCartDetails(type, guid); // need to restore cart data

      // If all resultCodes are the same and equal to M01 then go to cart
      this.loading = false;
      this.modalClose();
      this.goToPage(type, 'cart', contractId);

    } else {

      // Something went wrong so cart will be removed and user notified with an alert message
      this.modalClose();

      const params = {
        'type': type,
        'guid': this.dataCommerciale[type].cart.testa.c_guid,
        'anno': this.dataCommerciale[type].cart.testa.n_anno,
        'numeratore': this.dataCommerciale[type].cart.testa.c_numeratore,
        'ordine': this.dataCommerciale[type].cart.testa.n_ordine,
        'note': 'ERR502: cancellazione automatica da FE per fallimenti aggiornamento carrello'
      };

      await this.permanentlyDeleteCartCommle(params)

      throw {
        id: 'massiveAddToCartCommleGenericFail',
        message: langstore.t('not_all_product_added_retry', 'Non tutti i prodotti sono stati aggiunti al carrello. Riprova oppure contatta il supporto tecnico.')
      }
    }

    return false;

  } catch (error) {
    this.notificationAdd('ERR502: ' + error.message, error.id, 10000, "error");
  }
}


const addToCartOrdersCommle = async function (type, ordersList) {
  try {

    if (!ordersList.length) {
      throw {
        id: 'addToCartOrdersCommleMissingOrders',
        message: langstore.t('no_expo_order_addable_to_cart', 'Nessun ordine Expo da poter aggiungere al carrello')
      }
    }

    const scope = this.scopeTypeConverter(type);
    const client = toJS(this.dataCommerciale[type].client);

    const cart = toJS(this.dataCommerciale[type].cart);
    let guid = '';

    if ( !_.isEmpty(cart.testa) ) {
      guid = cart.testa.c_guid;
    } else {
      const guidWrapper = await this.getCartGuidCommle(scope, client.c_nome);
      guid = guidWrapper.c_guid;
      await this.setCartDetails(type, guid); // need to restore cart data
    }

    // prepare params for massive add-to-cart
    const _params = {
      'guid': guid,
      'lista_ordini': JSON.stringify(ordersList.map(item => item.key))
    };

    const added = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/modifica-ordini-expo',
      cacheAge: -1, // need to be sure that the cache is never used
      params: _params,
      userToken: this.userToken,
    });

    let resultCodes = [];

    if (added.status === 'OK' && added.data.length > 0) {
      const flatData = added.data.flatMap(ar => ar.map(obj => obj.id_messaggio));
      resultCodes = flatData.filter((value, index, self) => self.indexOf(value) === index);
    }

    if ( resultCodes.length === 1 && resultCodes.pop() === 'OK1' ) {

      // reload from server cart details before redirect user to cart
      await this.setCartDetails(type, guid); // need to restore cart data

      // If all resultCodes are the same and equal to M01 then go to cart
      this.goToPage(type, 'cart');

    }

  } catch (error) {
    this.notificationAdd('ERR506: ' + error.message, error.id, 10000, "error");
  }
}


const deleteRowFromCartCommle = async function (art, extraData) {
  try {

    const loadingKey = "deleteRowFromCartCommle";
    this.loadingAdd(loadingKey);

    const _params = {
      'guid_ordine': art.guid,
      'parziale': extraData?.parziale || '',
      'articolo': art.c_articolo,
      'scheda': art.c_scheda,
      "qta": 0
    }

    const result = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/elimina-prodotto',
      cacheAge: null,
      params: _params,
      userToken: this.userToken,
    });

    if (result && result.data) {
      switch (result.data.id_messaggio) {
        case 'M03': // M03	riga annullata
          runInAction(() => {
            this.setCartDetails(art.type, result.data.guid_ordine);
            this.dataCommerciale[art.type].cart.count -= result.data.qta_riga;
          });
          break;
        case 'M04': // M04 ultima riga annullata
          runInAction(() => {
            this.dataCommerciale[art.type].cart = {
              count:0,
              testa: {},
              righe: {}
            };
          });
          break;
        default:
          this.notificationAdd('Error Code ' + result.data.id_messaggio + '. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.'), "ecommError", 10000, "error");
          break;
      }
    }

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR503: ' + error.message, error.id, 5000, "error");
  }
}


const permanentlyDeleteCartCommle = async function (params) {
  try {

    const loadingKey = "permanentlyDeleteCartCommle";
    this.loadingAdd(loadingKey);

    const result = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/elimina',
      cacheAge: null,
      params: {
        guid: params.guid,
        anno: params.anno,
        numeratore: params.numeratore,
        ordine: params.ordine,
        nota: params.note
      },
      userToken: this.userToken,
    });

    if (result && result.data) {
      if (result.data.return_cod.includes('OK')) {
        await this.setCartDetails(params.type, params.guid);

        this.loading = false;
        this.modalClose();

      } else {
        this.notificationAdd('Error Code ' + result.data.return_cod + ' [' + result.data.return_desc + ']. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.'), "ecommError", 10000, "error");
      }
    }

    this.loadingRemove(loadingKey);

  } catch (error) {
    this.notificationAdd('ERR504: ' + error.message, error.id, 5000, "error");
  }
}


const confirmCartCommle = async function (params) {
  try {

    const dsignData = await this.getDataForDsign(params);

    const _params = {
      dsignData: dsignData,
      ...params
    };

    const result = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/conferma',
      cacheAge: null,
      params: _params,
      userToken: this.userToken,
    });

    this.modalClose();
    this.loading = false;

    if (result && result.data) {
      if (result.data.return_cod.includes('OK')) {
        runInAction(() => {
          this.setCartDetails(params.type, params.guid);
        });
      } else {
        this.notificationAdd('Error Code ' + result.data.return_cod + ' [' + result.data.return_desc + ']. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.'), "ecommError", 10000, "error");
      }
    }

  } catch (error) {
    this.notificationAdd('ERR505: ' + error.message, error.id, 10000, "error");
  }
}


const confirmPartialCartCommle = async function (params) {
  try {

    const result = await this.dataLayer({
      url: this.config.paths.apiURL + 'commle/carrello/conferma-parziale',
      cacheAge: null,
      params: params,
      userToken: this.userToken,
    });

    if (result && result.data) {

      this.modalClose();
      this.loading = false;

      if (result.data.attachments[0] && result.data.attachments[0].status !== 'UPLOADED') {
        throw {
          id: 'missingFileUploaded',
          message: langstore.t(result.data.attachments[0].status, result.data.attachments[0].status) // 'Errore nel caricamento del file'
        }
      }

      if (result.data.cart.c_stato_avanzamento !== 'COMPLETATO') {
        throw {
          id: 'unableToComplete',
          message: 'Error Code ' + result.data.return_cod + ' [' + result.data.return_desc + ']. ' + langstore.t('ecomm_error_code_generic_text', 'Contattare l\'assisistenza indicando il codice del carrello presente nell\'url.')
        }
      }

      this.goToPage('oggettistica', 'cart');

    }

  } catch (error) {
    this.notificationAdd('ERR507: ' + error.message, error.id, 5000, "error");
  }
}


const addAttachment = async function(file) {

  let size_enable = this.rootstore.config.upload.max_size;

  // case: file not valid
  if (!validateExtensionAttachments(file.name)) {
    this.rootstore.notificationAdd(this.langstore.t("filesUpload_format_error", "Il file non è di un formato permesso."), "filesUpload", 5000, "error");
    return;
  }

  attachmentUpload(file, this, 'oggettistica-' + this.ordCliParziale);

}


const removeAttachment = async function(name, ordCliParziale) {

  attachmentRemove(name, this, 'oggettistica-' + ordCliParziale);

}


/************************************************************************************************
 *
 *  Helper func
 *
 * */


const setCartLoading = function(trueOrFalse = true) {
  runInAction(() => {
    this.dataCommerciale.cartLoading = trueOrFalse;
  });
}


const openProductDetails = function (type, product, extraData) {
  const modal = toJS(this.modalItems);
  if (!modal.length) {
    const params = {
      type: type,
      product: product,
      extraData: extraData
    };
    this.openCommercialeModal('ModalProductDetails', params);
  }
}


const goToPage = function (type, page, contractId = null, parziale = false) {
  try {

    const permittedTypes = ['marketing', 'campionari', 'oggettistica', 'all'];

    if (!type && !permittedTypes.includes(type)) {
      throw {
        id: 'missingScopeType',
        message: langstore.t('', 'Ambito mancante o non ammesso.')
      }
    }

    if (page === 'cart' && !this.dataCommerciale[type].cart.testa.c_guid) {
      throw {
        id: 'missingCart',
        message: langstore.t('', 'Il carrello richiesto non esiste.')
      }
    }

    let route = '';
    switch (page) {
      case 'cart':
        route = `/commerciale/richiesta-${type}/carrello/${this.dataCommerciale[type].cart.testa.c_guid}`;
        if (parziale) {
          route = `/commerciale/richiesta-${type}/carrello/${this.dataCommerciale[type].cart.testa.c_guid}/parziale/${this.dataCommerciale[type].orders.active}`;
        }
        break;
      case 'list':
        const additionalUrl = contractId ? '/contratto/' + contractId : '';
        route = `/commerciale/richiesta-${type}/lista-${type === 'oggettistica' ? 'ordini' : 'prodotti'}${additionalUrl}`;
        // Expo user has only carts list
        if (this.dataCommerciale.userRole === 'EXPO') {
          route = '/commerciale/storico-ordini';
        }
        // id cart 'parziale' list url will be cart route
        if (parziale) {
          route = `/commerciale/richiesta-${type}/carrello/${this.dataCommerciale[type].cart.testa.c_guid}`;
        }
        break;
      case 'contracts':
        route = `/commerciale/richiesta-${type}/lista-contratti`;
        break;
    }

    this.routeChange(route);

  } catch (error) {
    this.notificationAdd('ERR601: ' + error.message, error.id, 5000, "error");
  }
}


const setUserRole = function () {
  const userInfo = toJS(this.userInfo);
  let role = ''
  if (userInfo.isSubAgente) role = 'SUBAGE';
  if (userInfo.isAgente)    role = 'AGE';
  if (userInfo.isCapoArea)  role = 'AM';
  if (userInfo.isExpoUser)  role = 'EXPO';
  if (userInfo.isCommleDataViewer) role = 'VIEWER';
  // console.log('>>> set user role to ', role);

  runInAction(() => {
    this.dataCommerciale.userRole = role;
  })

  return role;
}

const isEntityAddableToCart = function (type) {
  const userRole = toJS(this.dataCommerciale.userRole);
  const cart = toJS(this.dataCommerciale[type].cart);
  if (_.isEmpty(cart.testa)) return true;
  return userRole === cart.testa.c_stato_wf.split('_').at(1);
}

const langCodeConverter = function (val) {
  const lang = {
    '001': 'IT',
    '002': 'EN',
    '003': 'FR',
    '004': 'ES'
  };
  return lang[val.toUpperCase()] !== undefined ? lang[val.toUpperCase()] : Object.keys(lang).find(key => lang[key] === val);
}


const scopeTypeConverter = function (val) {
  const scopes = {
    'MKT': 'marketing',
    'OGG': 'oggettistica',
    'CMP': 'campionari',
    'CAL': 'ante',
    'OMG': 'omaggi',
    'PMKT': 'marketing',
    'POGG': 'oggettistica',
    'PCMP': 'campionari',
    'PCAL': 'ante',
    'POMG': 'omaggi',
    'ALL': 'all'
  };
  return scopes[val.toUpperCase()] !== undefined ? scopes[val.toUpperCase()] : Object.keys(scopes).find(key => scopes[key] === val);
}


const userRoleConverter = function (val) {
  const roles = {
    'SUBAGE': langstore.t('role_label_sub_agent','Subagente'),
    'AGE': langstore.t('role_label_agent','Agente'),
    'AM': langstore.t('role_label_area_manager','Capo area'),
    'CLIENTE': langstore.t('cliente','Cliente'),
    'EXPO': langstore.t('role_label_expo_team','Team Expo'),
    'INIZIO': langstore.t('started','Iniziato'),
    'CLIENTE_INIZIO': langstore.t('contract_forwarded','Contratto inoltrato'),
    'CLIENTE_FINE': langstore.t('contract_received','Contratto ricevuto'),
    'SEDE_INI': langstore.t('in_progress','In elaborazione'),
    'SEDE_FIN': langstore.t('end_processing','Fine elaborazione'),
    'FINE': langstore.t('concluded','Concluso'),
    'SEDE_ERR': langstore.t('processing_error','Errore elaborazione')
  };

  return roles[val.toUpperCase()] !== undefined ? roles[val.toUpperCase()] : Object.keys(roles).find(key => roles[key] === val);
}


const htmlizeArticleVariants = function (variants) {
  let variantsList = '';
  if (variants === '' || typeof variants === 'undefined') return variantsList;

  const variantsAr = variants.split("||").filter(v => v !== "");
  variantsAr.forEach(variant => {
    const [k, v] = variant.split("§§");
    variantsList += `<li><strong>${k}:</strong> ${v}</li>`;
  });

  return variantsList;
}


export {
  fakeProductGenerator,
  getClientsList,
  getArticleList,
  getContractList,
  getOrdersList,
  getAllCommercialeCarts,
  getExpoOrdersListFromCart,
  getCartGuidCommle,
  getCartParamsCommle,
  getDestinazioneByCNome,
  getDataForDsign,
  getModelsByAnte,

  setClient,
  setContracts,
  resetFiltersState,
  setFilteredArticle,
  setCartDetails,
  setCartExtraData,
  toggleCommleOrderList,
  setActiveSubOrder,

  commleMarketingInitProductsList,
  commleInitCart,
  commleCampionarioAnteInitProductsList,
  commleCampionariInitContractsList,
  commleCampionariInitProductsList,
  commleOggettisticaInitOrdersList,
  commleOmaggiInitProductsList,
  commleInitStoredCart,

  openCommercialeModal,
  clientListModalOpen,

  setCartStatus,
  addToCartCommle,
  massiveAddToCartCommle,
  addToCartOrdersCommle,
  deleteRowFromCartCommle,
  permanentlyDeleteCartCommle,
  confirmCartCommle,
  confirmPartialCartCommle,
  addAttachment,
  removeAttachment,

  setCartLoading,
  openProductDetails,
  goToPage,
  setUserRole,
  scopeTypeConverter,
  userRoleConverter,
  isEntityAddableToCart,
  langCodeConverter,
  htmlizeArticleVariants,

}
