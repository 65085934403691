import {runInAction} from "mobx";

const getNoteTecnicheDashboard = async function () {
    try {

        let response = await this.dataLayer({
            url: this.config.paths.apiURL + "note-tecniche/dashboard",
            cacheAge: this.config.cacheAPI.default,
            params: {},
            userToken: this.userToken,
        });

        runInAction(() => {
            if (response) {
                this.dataNoteTecnicheDashboard = response.data;
                this.dataNoteTecnicheDashboard.loading = false;
            }
        });

    } catch (error) {
        runInAction(() => {
            this.dataNoteTecnicheDashboard.loading = false;
        });
        console.error("Errore durante il recupero delle note da leggere:", error);
    }
};


export {
    getNoteTecnicheDashboard
};
