import { runInAction, toJS } from "mobx";
import ReactGA from "react-ga4";

const videoAcademyDashboard = async function () {

  if (!((this.dataAutorizzazioni || {}).view_video_academy || {}).abilitato) return;

  try {

    const _params = {
      limit: 6,
      dashboardonly: 1,
      folder: '/protected',
      c_utente: this.userInfo.c_utente,
      gruppi: !this.userInfo.internal ? this.userInfo.services[0].gruppi.flat() : ['*'], // need to pass user group to filter video
      lang: this.language
    };

    const videoList = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/videoacademy/list',
      cacheAge: null,
      params: _params,
      userToken: this.userToken,
    });

    if (videoList) {
      if (this.dataPim.videoAcademy.categories === null && this.dataPim.videoAcademy.tags === null) {
        await this.videoEntitiesList();
      }

      runInAction(() => {
        this.dataPim.videoAcademy.dashboard = videoList.data;
        this.dataAcademyVideoDashboard.loading = false;
      });
    }

  } catch (error) {
    runInAction(() => {
      this.dataAcademyVideoDashboard.loading = false;
      this.dataAcademyVideoDashboard.count = 0;
    });
    console.error("Errore durante il recupero della lista video academy:", error);
  }
}

const videoAcademyList = async function () {

  const loadingKey = "videoAcademyList";
  this.loadingAdd(loadingKey);

  if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
    ReactGA.event({
      category: "academy2.0", action: "asset", label: 'listed videos'
    });
  }

  const _params = {
    folder: '/protected',
    c_utente: this.userInfo.c_utente,
    gruppi: !this.userInfo.internal ? this.userInfo.services[0].gruppi.flat() : ['*'], // need to pass user group to filter video
    lang: this.language
  };

  const videoList = await this.dataLayer({
    url: this.config.paths.apiURL + 'pim/videoacademy/list',
    cacheAge: null,
    params: _params,
    userToken: this.userToken,
  });

  if (videoList && videoList.data) {
    // console.log('>>> videoList ', videoList);

    if (this.dataPim.videoAcademy.categories === null && this.dataPim.videoAcademy.tags === null) {
      await this.videoEntitiesList();
    }

    runInAction(() => {
      this.dataPim.videoAcademy.list = videoList.data;
      if (typeof this.dataPim.videoAcademy.orderAndFilter === 'undefined') {
        this.dataPim.videoAcademy.orderAndFilter = this.defineOrderAndFilter();
      }
      this.loading = false;
    });
  }
  this.loadingRemove(loadingKey);

}


const videoEntitiesList = async function () {

  const _params = {
    folder: '/protected',
    c_utente: this.dataUserDetails.c_utente,
    lang: this.language
  }

  const entityTypes = ['categories', 'tags', 'tree'];

  for (const type of entityTypes) {
    const entity = await this.dataLayer({
      url: this.config.paths.apiURL + 'pim/videoacademy/' + type,
      cacheAge: null,
      params: _params,
      userToken: this.userToken,
    });

    if (entity) {
      // console.log('>>> entity ' + type + ': ', entity);
      runInAction(() => {
        this.dataPim.videoAcademy[type] = entity.data;
      });
    }
  }

}


const setOrderAndFilterVideoList = function (type, value) {

  const loadingKey = "setOrderAndFilterVideoList";
  this.loadingAdd(loadingKey);

  if (type) {
    let filter = toJS(this.dataPim.videoAcademy.orderAndFilter);
    switch (type) {
      case 'reset':
        filter = this.defineOrderAndFilter(value);
        break;
      case 'categories':
        filter.categories[value.name][1] = value.checked
        filter.isActive.categories = Object.values(filter.categories).some(prop => prop[1]);
        break;
      case 'tags':
        filter.tags[value.name][1] = value.checked
        filter.isActive.tags =  Object.values(filter.tags).some(prop => prop[1]);
        break;
      default:
        filter[type] = value;
        filter.isActive.others = true;
        break;
    }
    runInAction(() => {
      this.dataPim.videoAcademy.orderAndFilter = filter;
    });
  }

  this.loadingRemove(loadingKey);

}


const defineOrderAndFilter = function (value) {

  const videoAcademyObj = toJS(this.dataPim.videoAcademy);
  const filters = videoAcademyObj.orderAndFilter;

  // set default
  let values = {
    searchBy: '',
    sorting: 'dt_ins',
    sortingDirection: 'desc',
    categories: null,
    tags: null,
    isActive: {
      others: false,
      categories: false,
      tags: false
    }
  };
  
  if (value === undefined || value === null || value === 'categories') {
    // reset values for categories
    values.categories = videoAcademyObj.categories.reduce((acc, obj) => {
      // default "code" = ["disabled": true, "checked": false]
      acc[obj.code] = [true, false];
      return acc;
    }, {});
  } else {
    // user removed only tags, need to preserve the others
    values.searchBy             = filters.searchBy;
    values.sorting              = filters.sorting;
    values.sortingDirection     = filters.sortingDirection;
    values.categories           = filters.categories;
    values.isActive.others      = filters.isActive.others;
    values.isActive.categories  = filters.isActive.categories;
  }

  if (value === undefined || value === null || value === 'tags') {
    // reset values for tags
    values.tags = videoAcademyObj.tags.reduce((acc, obj) => {
      // default "code" = ["disabled": true, "checked": false]
      acc[obj.code] = [true, false];
      return acc;
    }, {});
  } else {
    // user removed only categories, need to preserve the others
    values.searchBy             = filters.searchBy;
    values.sorting              = filters.sorting;
    values.sortingDirection     = filters.sortingDirection;
    values.tags                 = filters.tags;
    values.isActive.others      = filters.isActive.others;
    values.isActive.tags        = filters.isActive.tags;
  }

  const results = {
    searchBy: values.searchBy,
    sorting: values.sorting,
    sortingDirection: values.sortingDirection,
    categories: values.categories,
    tags: values.tags,
    isActive: {
      others: values.isActive.others,
      categories: values.isActive.categories,
      tags: values.isActive.tags
    }
  }

  return results;

};


const videoDetails = async function () {

  const loadingKey = "videoDetails";
  this.loadingAdd(loadingKey);

  const videoId = parseInt(this.routeParamsCurrent.videoId)

  let detailsVideo;
  if (typeof this.dataPim.videoAcademy.list?.videos !== 'undefined') {

    // prevent call twice BE if video data is already available
    detailsVideo = {
      data: {
        token: this.dataPim.videoAcademy.list.token,
        videos: [ this.dataPim.videoAcademy.list.videos.find(item => item.id === videoId) ]
      }
    };

  } else {

    const _params = {
      id: videoId,
      c_utente: this.userInfo.c_utente,
      gruppi: !this.userInfo.internal ? this.userInfo.services[0].gruppi.flat() : ['*'], // need to pass user group to filter video
      folder: "/protected",
      lang: this.language
    }

    detailsVideo = await this.dataLayer({
      url: `${this.config.paths.apiURL}pim/videoacademy/list`,
      cacheAge: null,
      params: _params,
      userToken: this.userToken,
    });

  }


  if (!!detailsVideo?.data) {
    if (this.dataPim.videoAcademy.categories === null && this.dataPim.videoAcademy.tags === null) {
      await this.videoEntitiesList();
    }
    runInAction(() => {
      this.dataPim.videoAcademy.singleVideo = detailsVideo.data;
    })
  }

  this.loadingRemove(loadingKey);
}

export {
  videoAcademyDashboard,
  videoAcademyList,
  videoEntitiesList,
  setOrderAndFilterVideoList,
  defineOrderAndFilter,
  videoDetails
}

