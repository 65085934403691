import { runInAction } from "mobx";
import { toJS } from "mobx";
import Route from "route-parser";
import langstore from "../../system/i18n/translator.js";

const routePopupChange = function (routePopup, routePopupClass) {
  runInAction(() => {
    this.routePopup = route;
    this.routePopupClass = routePopupClass ? routePopupClass : "ts_popup_large";
  });
};

const routePopupClose = function (route) {
  runInAction(() => {
    this.routePopup = null;
  });
};

const routesTreeToggle = function (route) {
  let menusOpen = toJS(this.menusOpen);
  if (menusOpen.indexOf(route) >= 0) {
    menusOpen.splice(menusOpen.indexOf(route), 1);
  } else {
    //menusOpen.push(route); // ripristinare per attilete il dropdown multiplo
    menusOpen = [route]; // commentare per disabilitare l'accordion
  }

  runInAction(() => {
    this.menusOpen = menusOpen;
  });
};

const routesTreeOpen = function (route) {
  let menusOpen = toJS(this.menusOpen);
  if (menusOpen.indexOf(route) < 0) {
    //menusOpen.push(route); // ripristinare per attilete il dropdown multiplo
    menusOpen = [route]; // commentare per disabilitare l'accordion
  }

  runInAction(() => {
    this.menusOpen = menusOpen;
  });
};

const routeToRedirect = function (route) {
  runInAction( () => {
    this.routeRedirect = route;
  });
};

const routeChange = function (route) {
  runInAction(() => {
    this.route = route;
  });
};

/**
 Save route temporary params
 **/
const routeParamsCacheSave = function (index, params) {
  if (index && params) {
    let dataObject = toJS(this[index]);
    if (dataObject) {
      // for(let property in params){
      //   dataObject[property] = params[property];
      // }
      Object.assign(dataObject, params);
      this[index] = dataObject;
    }
  }
};

const routeParamsCacheSave2 = function (index, params) {
  if (index && params) {
    let data = toJS(this.cacheParams);

    let dataObject = {};
    if (data === null) {
      data = {};
    }
    if (data) {
      dataObject = data[index];
    }

    if (typeof dataObject === "object" && dataObject !== null) {
      Object.assign(dataObject, params);

      // remove null values from params (used for custom filters)
      Object.keys(dataObject).forEach((key) => {
        if (dataObject[key] === null || dataObject[key] === undefined) {
          delete dataObject[key];
        }
      });
    } else {
      dataObject = params;
    }

    data[index] = dataObject;

    runInAction(() => {
      this.cacheParams = data;
    });
  }
};

const routeParamsCacheResetFields = function (index, fields) {
  if (index) {
    let data = toJS(this.cacheParams);

    let dataObject = {};
    if (data === null) {
      data = {};
    }
    if (data) {
      dataObject = data[index];
    }

    if (typeof dataObject === "object" && dataObject !== null) {
      Object.keys(dataObject).forEach((key) => {
        if (fields.includes(key)) {
          delete dataObject[key];
        }
      });
    }

    data[index] = dataObject;

    runInAction(() => {
      this.cacheParams = data;
    });
  }
};

// const routeParamsCacheSet = function(index,params){
//   if(index && params){
//     params = JSON.stringify(params);
//     let data = toJS(this.cacheParams);
//     let dataObject = toJS(data[index]) || {};
//     if(dataObject){
//       Object.assign(dataObject,params);
//       data[index] = dataObject;
//       this.cacheParams = data;
//     }
//   }
// };

const routeParamsCacheGet = function (index) {
  let dataObject = {};
  if (index && this && this.cacheParams) {
    let data = this.cacheParams;
    if (data) {
      data = toJS(data);
    }
    dataObject = data[index] || {};
  }
  return dataObject;
};

/**
 Reload the current route
 **/
const routeReload = function (params, options) {
  let routes = this.routes;
  let routeParams = this.routeParamsCurrent;
  let routeCurrent = null;
  let routeTitle = "";
  let cacheAge = (options && options.cacheAge) || this.config.cacheAPI.default;

  //console.log(routes)

  // find route
  for (let i = 0; i < routes.length; i++) {
    let route = routes[i];
    if (route.id === routeParams.routeId) {
      routeCurrent = route;
      break;
    }
  }

  // run actions
  if (routeCurrent && this[routeCurrent.action]) {
    this[routeCurrent.action](params, { cacheAge: cacheAge });
  }
};

const routeChangeWithLink = function (_path, _external, _target, _params) {
  let target = _target ? _target : "_self";
  let pathWithParams = [_path];

  if (_params) {
    pathWithParams.push(_params);
  }

  pathWithParams = pathWithParams.join("?");

  if (target === "_blank") {
    window.open(pathWithParams, target);
  } else {
    this.routeChange(pathWithParams);
  }
};

const bookmarkSave = function () {
  let routes = this.routes;
  let routeParams = this.routeParamsCurrent;
  let routeCurrent = null;
  let bookmarks = toJS(this.bookmarks);

  for (let i = 0; i < routes.length; i++) {
    let route = routes[i];
    if (route.id === routeParams.routeId) {
      routeCurrent = route;
      break;
    }
  }

  let bookmark = {
    url: window.location.href.replace(this.config.app.path, ""),
    route: routeCurrent,
  };

  bookmarks.push(bookmark);
  this.bookmarks = bookmarks;
  this.notificationAdd(
    langstore.t("bookmark_saved", "Bookmark salvato"),
    "bookmark_saved"
  );
};

const bookmarkRemove = function (url) {
  let bookmarks = toJS(this.bookmarks);
  let bookmarksNew = [];

  for (let i = 0; i < bookmarks.length; i++) {
    let bookmark = bookmarks[i];
    if (bookmark.url !== url) {
      bookmarksNew.push(bookmark);
    }
  }

  this.bookmarks = bookmarksNew;
  this.notificationAdd(
    langstore.t("bookmark_removed", "Bookmark rimosso"),
    "bookmark_removed"
  );
};

const routesLoadOld = function (dynamicRoutes) {
  let routes = toJS(this.routes);
  routes = routes.concat(dynamicRoutes);

  runInAction(() => {
    this.routes = routes;
    this.routesLoaded = true;
  });
};

const _paramStringToObj = function (str) {
  if (!str) {
    return {};
  }
  return str
    .replace(/\?/, "")
    .split("&")
    .reduce((acc, param) => {
      const splitParam = param.split("=");
      if (splitParam[0] && splitParam[1]) {
        acc[splitParam[0]] = splitParam[1];
      }
      return acc;
    }, {});
};

const ssoRedirect = async function (path, params) {
  let loadingKey = "ssoredirect";
  let windowReference = window.open();

  this.loadingAdd(loadingKey);

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "sso/gettoken",
    cacheAge: 0,
    userToken: this.userToken,
  });

  if (!(response && response.data)) {
    this.notificationAdd(
      langstore.t("sso_redirect_error", "Errore redirect"),
      "redirecterror",
      null,
      "error"
    );
  }

  const menuParams = _paramStringToObj(params);

  //console.log(response);

  const ssoParams = {
    ...menuParams,
    ...response.data,
  };

  const stringifiedParams = [...Object.keys(ssoParams)]
    .map((spk) => `${spk}=${ssoParams[spk]}`)
    .join("&");

  const redirectUrl = [path, stringifiedParams].join("?");
  windowReference.location = `${redirectUrl}`;

  this.loadingRemove(loadingKey);
};

export {
  routeParamsCacheSave,
  routeToRedirect,
  routeChange,
  routesTreeToggle,
  routesTreeOpen,
  routeChangeWithLink,
  routeReload,
  bookmarkSave,
  bookmarkRemove,
  routeParamsCacheSave2,
  routeParamsCacheGet,
  ssoRedirect,
  routeParamsCacheResetFields
};
