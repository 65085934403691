const updateComunicazioni = async function () {
    let isComunicazioniActive = this.config && this.config.comunicazioni === true;
    let url = this.config.paths.apiURL + "comunicazioni";

    if (isComunicazioniActive) {
        let response = await this.dataLayer({
            url: url,
            method: "POST",
            cacheAge: 0,
            userToken: this.userToken
        });

        if (response && response.data) {
            this.messages = response.data;
        }
    }

}

const updateComunicazioniInit = async function () {
    let isComunicazioniActive = this.config && this.config.comunicazioni === true;
    if (isComunicazioniActive) {
        this.loadingAdd("updateComunicazioniInit");
        await this.updateComunicazioni();
        this.loadingRemove("updateComunicazioniInit");
    }
}

const comunicazioniRead = async function (messageId) {
    let url = this.config.paths.apiURL + "/comunicazioni/detail";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            id: messageId
        },
        userToken: this.userToken
    });

    if (response.data) {
        this.modalOpen("ModalMessage", response.data);
    }

}

export {
    updateComunicazioni,
    comunicazioniRead,
    updateComunicazioniInit
}
