// {
//     "dt_richiesta": "2019-11-26T17:07:16.807Z",
//     "n_evento": 330659,
//     "n_anno": 2019,
//     "c_numeratore": "OCL",
//     "n_ordine": 9481,
//     "c_stato": 10,
//     "ds_stato": "DA ELABORARE",
//     "dt_elaborazione": null,
//     "log_errore": " "
// }

/*
  Validatori per azioni
  Possono variare per cliente
  Si settano nel config
*/

const stc_OrderComplete = function(order){
  let permission = (
    order.fg_ordine_annullato == 0 && 
    order.fg_stato_ordine <= 3 && 
    order.fg_stato_ordine >= 0
  );
  return{
    permission: permission,
    message: "Messaggio da definire"
  }
}

const stc_OrderCancel = function(order){
  let permission = (
    order.fg_ordine_annullato == 0 && 
    order.fg_stato_ordine <= 50 && 
    order.fg_stato_ordine >= 0 &&
    order.n_bolla == 0
  );
  return {
    permission: permission,
    message: "Messaggio da definire"
  }
}

const orderEventValidators = {
  stc_OrderComplete,
  stc_OrderCancel
}


/*
  Azioni di utilità
*/

const canConfirm = function(order,config){
  let can = false;
  let validator = orderEventValidators[config.format.orderCompleteFilter];
  if( validator && order ){
    can = validator(order).permission;
  }
  return can;
}

const canCancel = function(order,config){
  let can = false;
  let validator = orderEventValidators[config.format.orderCancelFilter];
  if( validator && order ){
    can = validator(order).permission;
  }
  return can;
}


const findOrderEvent = function(order,events){

  if(order && events){

    let ev = events.find((event)=>{
      return (
        event.n_anno === order.n_anno &&
        event.c_numeratore === order.c_numeratore &&
        event.n_ordine === order.n_ordine
      )
    });

    if(ev){
      return ev;
    }

  }

  return {};

}

const findOrderEventInfo = function(order,events,config){

  let eventInfo = {
    canCancel: false,
    canConfirm: false,
    ds_stato: "nodata",
    event: {}
  }

  if(order){
    eventInfo.canCancel = canCancel(order,config);
    eventInfo.canConfirm = canConfirm(order,config);
  }

  if(order && events && events !== null){
    let ev = findOrderEvent(order,events);
    if(ev){
      eventInfo.event = ev;
      eventInfo.ds_stato = ev.ds_stato;
    }
  }

  return eventInfo;

}


/*
  Computed per verificare lo stato delle azioni per singolo ordine
*/

const orderEventsInfoCurrent = function(){

  let order = this.dataOrder && this.dataOrder.dettaglioOrdine ? this.dataOrder.dettaglioOrdine : {};
  let events = this.dataEventiOrdine;
  return findOrderEventInfo(order,events,this.config);
}

const findOrderEventInfoAll = function(){
  let orders = this.dataOrders || [];
  let events = this.dataEventiOrdine;
  return orders.map((order)=>{
    findOrderEventInfo(order,events,this.config);
  });
}

/*
  Computed per notifiche sui messaggi
*/

const orderEventNotificactionCurrent = function(){
  return this.dataEventiNotifiche.find((event)=>{
    event.read === false;
  });
}



export {
  findOrderEvent,
  orderEventValidators,
  findOrderEventInfo,
  findOrderEventInfoAll,
  orderEventsInfoCurrent,
  orderEventNotificactionCurrent,
  canCancel,
  canConfirm
}