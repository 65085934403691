const getDashboard = function (params, options) {
    if (this.userInfo.internal !== true) { // prevent unnecessary api calls if logged in as internal user
        this.getLastMessages();
        // this.getWarrantiesToBeActivatedDashboard();
        // this.videoAcademyDashboard();
    }
};

export {
    getDashboard
}
