import {runInAction} from 'mobx';
import {Request, Response} from '../../system/utilities/network.js';
import {toJS} from 'mobx';


const clientlist = async function (params, options) {

    let loadingKey = 'clientList';

    // spinner
    this.loadingAdd(loadingKey);

    let localParams = this.routeParamsCacheGet("clientsParams");
    let _params = {
        "PageNumber": localParams.PageNumber || 1,
    }

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'cliente',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    // business logic
    if (response) {
        this.dataClients = response.data;
    }
    this.loadingRemove(loadingKey);

}

const clientdetail = async function (params, options) {
    let loadingKey = 'clientDetail';

    // spinner
    this.loadingAdd(loadingKey);

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'cliente/dettaglio',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: toJS({
            c_nome: parseInt(this.routeParamsCurrent.idClient)
        }),
        userToken: this.userToken
    });


    const {data} = response;
    const clientDocuments = data?.pratiche || [];
    let otherFiles = [];
    let filesFlat = [];
    let hasNullFolder = false;

    const clientDocumentsPromises = clientDocuments.map(cd => {
        return this.dataLayer({
            url: this.config.paths.apiURL + 'pratiche/infofiles',
            cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
            params: {
                c_pratica: cd.c_pratica,
                c_pratica_guid: cd.c_pratica_guid
            },
            userToken: this.userToken
        });
    });

    const docInfoRespArray = await Promise.all(clientDocumentsPromises);

    for (var i = 0; i < docInfoRespArray.length; i++) {
        const files = docInfoRespArray[i];

        if (files.data && files.data.info && files.data.info.length >= 1) {
            let files3 = [];
            let files2 = files.data.info.reduce((total, f) => {
                let cartella = (f.fg_manuale === 1 || !f.cartella) ? "000" : f.cartella;
                let filename = f.File_name;
                let extension = null;
                let viewable = false;
                let extensions = this.config.viewableExtensions;

                filename = filename.split("/").pop();
                filename = filename.split("\\").pop();
                //filename = filename.replace(/\s+/g, '-').toLowerCase();
                extension = filename.split(".").pop().toLowerCase();
                viewable = extensions.includes(extension);
                f.cartella = cartella;
                f.DS_filename = filename;
                f.viewable = viewable;
                f.pratica = clientDocuments[i];
                f.ds_tipo_doc = clientDocuments[i].ds_tipo_doc;
                f.fileLabel = f.ds_file || filename;


                if (total[cartella]) {
                    total[cartella].push(f);
                } else {
                    total[cartella] = [f];
                }

                if (cartella === "000") {
                    hasNullFolder = true;
                }

                return total;
            }, {});

            for (let key in files2) {
                let sorted = files2[key].sort((a, b) => {
                    return (
                        a.c_versione - b.c_versione || a.c_riga - b.c_riga
                    )
                });
                files3.push({cartella: key, count: sorted.length, items: sorted});
                filesFlat = filesFlat.concat(sorted);
            }
        }
    }

    data.filesFlat = filesFlat;
    data.hasNullFolder = hasNullFolder;

    // business logic
    runInAction(() => {
        if (response) {
            this.dataClient = data;
        }
        this.loadingRemove(loadingKey);
    });

}

const clientcontacts = async function (params, options) {

    let loadingKey = 'clientContacts';
    let localParams = this.routeParamsCacheGet("contactsParams");

    // spinner
    this.loadingAdd(loadingKey);

    let _params = {
        "PageNumber": localParams.PageNumber || 1,
    }


    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'cliente/rubrica',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    // business logic
    runInAction(() => {
        if (response) {
            this.dataContacts = response.data.rubrica;
        }
        this.loadingRemove(loadingKey);
    });


}

export {clientlist, clientdetail, clientcontacts}
