import * as agents from '../../src/application/actions/agents.js';
import * as cliente from '../../src/application/actions/cliente.js';
import * as invoices from '../../src/application/actions/invoices.js';
import * as ddt from '../../src/application/actions/ddt.js';
import * as orders from '../../src/application/actions/orders.js';
import * as ordersAndReplacements from '../../src/application/actions/ordersAndReplacements.js';
import * as note_tecniche from '../../src/application/actions/note_tecniche.js';
import * as comunicazioni from '../../src/application/actions/comunicazioni.js';
import * as reclami from '../../src/application/actions/reclami.js';
import * as tweb from '../../src/application/actions/tweb.js';
import * as email from '../../src/application/actions/email.js';
import * as profile from '../../src/application/actions/profile.js';
import * as pim from '../../src/application/actions/pim.js';
import * as promotions from '../../src/application/actions/promotions.js';
import * as statistics from '../../src/application/actions/statistics.js';
import * as internal from '../../src/application/actions/internal';
import * as dashboard from '../../src/application/actions/dashboard';
import * as assistenza from "../../src/application/actions/assistenza.js";
import * as garanzia from "../../src/application/actions/garanzia.js";
import * as crm from "../../src/application/actions/crm.js";
import * as areaTecnica from "../../src/application/actions/areaTecnica.js";
import * as ecommerce from "../../src/application/actions/ecommerce.js";
import * as videoAcademy from "../../src/application/actions/academy.js";
import * as commerciale from "../../src/application/actions/commerciale.js";
import * as newsletter from "../../src/application/actions/newsletter.js";
import * as eldomOutOfStock from "../../src/application/actions/eldomOutOfStock.js";
import * as users from '../../src/application/actions/users.js';

const actions = {
    ...agents,
    ...cliente,
    ...invoices,
    ...ddt,
    ...orders,
    ...ordersAndReplacements,
    ...note_tecniche,
    ...comunicazioni,
    ...reclami,
    ...tweb,
    ...email,
    ...profile,
    ...pim,
    ...promotions,
    ...statistics,
    ...internal,
    ...dashboard,
    ...assistenza,
    ...garanzia,
    ...crm,
    ...areaTecnica,
    ...ecommerce,
    ...videoAcademy,
    ...commerciale,
    ...newsletter,
    ...eldomOutOfStock,
    ...users
}

export default actions;
