import {runInAction, toJS} from "mobx";

const loadClientiDisponibili = async function () {
    let url = this.config.paths.apiURL + "acl";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        userToken: this.userToken
    });

    if (response && response.data) {
        runInAction(() => {
            this.acl = response.data;
        });
    }
};

const aclAddUserModal = function (docs) {
    runInAction(() => {
        this.dataDocumentInfo = {};
        this.dataDocumentActive = docs;
        this.modalOpen("ModalAcl");
    });
};

const aclCreateUser = async function (params) {
    let loadingKey = 'aclCreateUser';
    this.loadingAdd(loadingKey);

    let url = this.config.paths.apiURL + "acl/user/create";
    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            ds_utente: params.aclUserDescrizione,
            nome: params.aclUserNome,
            cognome: params.aclUserCognome,
            c_nomi: params.aclUserClienti.length > 0 ? params.aclUserClienti.slice() : [],
            e_mail_utente: params.aclUserEmail,
            telefono_utente: params.aclUserTelefono,
            gruppi: params.aclUserGruppi,
            email: true
        },
        userToken: this.userToken
    });

    if (response && response.data) {
        this.loadingRemove(loadingKey);
        this.getInfoUtente().then(() => {
            this.formData = {};
            this.modalCloseAll();
        });
    }
}

const aclShowUserData = async function (c_utente) {
    let url = this.config.paths.apiURL + "acl/user";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    if (response && response.data) {
        let obj = {
            "clienti": response.data.clienti,
            "utente": response.data.utente,
            "gruppiAcl": response.data.gruppiAcl,
            "gruppiAclPadre": response.data.gruppiAclPadre
        }
        runInAction(() => {
            this.modalOpen("ModalAcl", obj);
        });
    }
}

const aclSaveUser = async function (params) {
    let loadingKey = 'aclSaveUser';
    this.loadingAdd(loadingKey);

    let url = this.config.paths.apiURL + "acl/user/save";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: params.aclUtente,
            ds_utente: params.aclUserDescrizione,
            nome: params.aclUserNome,
            cognome: params.aclUserCognome,
            c_nomi: params.aclUserClienti.length > 0 ? params.aclUserClienti.slice() : [],
            e_mail_utente: params.aclUserEmail,
            telefono_utente: params.aclUserTelefono,
            gruppi: params.aclUserGruppi,
            email: true,
            force_logout: params.forceLogout || false
        },
        userToken: this.userToken
    });

    if (response && response.data) {
        this.loadingRemove(loadingKey);
        this.getInfoUtente().then(() => {
            this.formData = {};
            this.modalCloseAll();
        });
    }
}

const aclDisableUser = async function (c_utente, row) {
    let url = this.config.paths.apiURL + "acl/user/disable";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    if (response && response.status === 'OK') {
        runInAction(() => {
            this.dataUserDetails.utentiSlave[row].annullato = 1;
        });
    }
}

const aclEnableUser = async function (c_utente, row) {
    let url = this.config.paths.apiURL + "acl/user/enable";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    if (response && response.status === 'OK') {
        runInAction(() => {
            this.dataUserDetails.utentiSlave[row].annullato = 0;
        });
    }
}

const aclDeleteUser = async function (c_utente, row) {
    let url = this.config.paths.apiURL + "acl/user/delete";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    if (response && response.status === 'OK') {
        runInAction(() => {
            this.dataUserDetails.utentiSlave[row].cancellato = 1;
        });
    }
}

export {
    loadClientiDisponibili,
    aclAddUserModal,
    aclCreateUser,
    aclShowUserData,
    aclSaveUser,
    aclEnableUser,
    aclDisableUser,
    aclDeleteUser
}
